import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Form } from 'react-bootstrap';
import classnames from 'classnames';

import useValidation from './useValidation';
import InputErrorMessage from 'modules/common/form/input-error-message.component';
import SubText from 'modules/common/sub-text.component';
import './inputs.scss';

function WinRedInput({
  placeholder = '',
  containerClassName = '',
  className = '',
  classNameContainer = '',
  onChange,
  name = '',
  value,
  maxLength = 255,
  prepend,
  append,
  onFocus,
  onBlur,
  children,
  validators,
  errorIcon,
  validateOnMount,
  description,
  subDescription,
  doNotWrap,
  removeBorder = false,
  validateOnEveryChange,
  ...rest
}) {
  const ref = useRef();

  const [focus, setFocus] = useState(false);
  const [validationBlur, validationError, validateInput] = useValidation({
    value,
    validators,
    validateOnMount,
    onBlur,
    ref: ref?.current,
  });

  const _onChange = useCallback(
    event => {
      const {
        target: { value },
      } = event;
      onChange(event);
      setFocus(!!value);
    },
    [onChange]
  );

  const handleFocusChange = useCallback(
    event => {
      if (onFocus) onFocus(event);
      setFocus(true);
    },
    [onFocus]
  );

  const handleBlurChange = useCallback(
    event => {
      validationBlur(event);
      setFocus(value === 0 ? true : !!value);
    },
    [validationBlur, value]
  );

  let labelClass = focus || (value && value.length !== 0);

  // when wrapped in a inputgroup the label goes missing
  const Wrapper = append || prepend ? InputGroup : 'div';

  const handleOnWheel = e => {
    e.target.blur();
  };

  useEffect(() => {
    if (validateOnEveryChange && value) {
      validateInput();
    }
  }, [value, validateInput, validateOnEveryChange]);

  return (
    <div className={classnames('winred-input2', classNameContainer)}>
      <Wrapper>
        {prepend ? (
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        ) : null}
        <div
          className={classnames('float-label', { 'no-wrap': doNotWrap })}
          onBlur={handleBlurChange}
          onFocus={handleFocusChange}
        >
          <Form.Control
            ref={ref}
            name={name}
            onChange={_onChange}
            aira-label={placeholder}
            className={classnames(
              {
                'winred-input-error': !!validationError,
                'input-with-prepend': !!prepend,
                'remove-border': removeBorder,
              },
              className
            )}
            maxLength={maxLength}
            value={value}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onWheel={handleOnWheel}
            {...rest}
          />
          <label
            className={classnames({
              'label label-float': labelClass,
              label: !labelClass,
              'winred-input-float-error': !!validationError,
            })}
          >
            {placeholder}
          </label>
          {append ? (
            <InputGroup.Append>
              <InputGroup.Text>{append}</InputGroup.Text>
            </InputGroup.Append>
          ) : null}
        </div>
      </Wrapper>

      <InputErrorMessage errorMessage={validationError} errorIcon={errorIcon} />
      {description ? (
        <SubText description={description} description2={subDescription} removeMarginTop={validationError} />
      ) : null}
      {children}
    </div>
  );
}

WinRedInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,

  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  prepend: PropTypes.node,
  append: PropTypes.node,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  doNotWrap: PropTypes.bool,
};

export default WinRedInput;
