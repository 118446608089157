import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { convertEpochTime } from 'core/utilities';
import { Button, IndexTableContextMenu, Typography } from 'modules/common';
import { PageTagItem } from 'modules/pages/pages/page-tags/page-tag.component';
import { Col, Dropdown, Row } from 'react-bootstrap';

export const ActiveTabTableMobile = ({ items, dropdownAction, openMainDrawer }) => {
  if (!items) return null;

  return items?.map(item => {
    const { revv_uid, name, color, description, created_at, creator, subTags } = item;

    return (
      <Fragment key={revv_uid}>
        <div className="list-item-card" tabIndex="0" role="link">
          <Row className="no-gutters">
            <Col xs="9">
              <div className="top-row flex justify-between items-start">
                <div className="flex justify-start items-center">
                  <PageTagItem data={{ name, color }} />
                </div>
                <Button skinnyButton square variant="alt" onClick={() => openMainDrawer({ isEdit: true, data: item })}>
                  Manage {subTags ? `(${subTags?.length})` : ''}
                </Button>
              </div>
              <Typography className="mt-2">{description}</Typography>

              <Typography>
                {convertEpochTime(created_at)} <br />
                {creator && `by ${creator}`}
              </Typography>
            </Col>
            <Col xs="3" className="flex justify-end">
              <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
                <Dropdown.Item eventKey="manage">Manage</Dropdown.Item>
                <Dropdown.Item eventKey="archive">Archive</Dropdown.Item>
              </IndexTableContextMenu>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  });
};

ActiveTabTableMobile.propTypes = {
  items: PropTypes.array.isRequired,
  dropdownAction: PropTypes.func.isRequired,
  openMainDrawer: PropTypes.func.isRequired,
};
