import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Table, TableRowExpanded, ToolTip, Typography, WinRedChip } from 'modules/common';
import 'modules/pages/pages.scss';

import { computePageIcon, computePageIconColor, computePageTooltip } from '../ui.tools';
import { convertEpochTime } from 'core/utilities';
import { TagIndexList } from '../page-tags/tag-index-list.component';
import GrossResultsDisplay from 'modules/pages/components/grossResultsDisplay.component';
import { PagesDropdownMenu } from './dropdown-menu.component';
import { ExpandedRowBody } from './expanded-row-body.component';

function PagesTableDesktop({
  items,
  navigateToItem,
  dropdownAction,
  expandItem,
  expandedRows,
  profileCandidate,
  refetch,
  uiFilter,
  toggleBulkAddTag,
}) {
  const showBulkTagIcon = useMemo(() => {
    return false;
    // return uiFilter.length > 0;
  }, []);
  const tableBody = items.map(item => {
    const {
      revv_uid,
      created_at,
      internalName,
      slug,
      type,
      pageGrossResults,
      billPayExists,
      authorEmail,
      backgroundColor,
      backgroundAssignedImage,
      tags,
    } = item;
    const icon = computePageIcon(item);
    const iconColor = computePageIconColor(item);
    const toolTip = computePageTooltip(item);
    const navigateHandler = () => navigateToItem(revv_uid);
    const isExpanded = expandedRows[revv_uid];
    const _expandItem = event => expandItem(event, revv_uid);

    return (
      <Fragment key={revv_uid}>
        <Table.Row key={revv_uid} className="p-0" onClick={navigateHandler} role="link" tabIndex="0">
          <Table.Cell textAlign="center" className="icon-cell">
            <ToolTip tooltip={toolTip}>
              <Icon icon={icon} color={iconColor} />
            </ToolTip>
          </Table.Cell>
          <Table.Cell textAlign="center" className="icon-cell">
            {backgroundAssignedImage ? (
              <div
                className="page-image-rectangle"
                style={{ backgroundImage: `url(${backgroundAssignedImage?.image?.thumbnailUrl})` }}
              ></div>
            ) : (
              <div className="page-image-rectangle" style={{ backgroundColor: backgroundColor }}></div>
            )}
          </Table.Cell>
          <Table.Cell>
            <Typography variant="bodyMedium" className="c--mirage mb-0 break-words">
              {internalName || ''}
            </Typography>
            <Typography className="mb-0">{slug}</Typography>
          </Table.Cell>
          <Table.Cell>
            {convertEpochTime(created_at)} <br />
            {authorEmail && `by ${authorEmail}`}
          </Table.Cell>
          <Table.Cell noClick>
            <TagIndexList pageRevvUid={revv_uid} data={tags} refetch={refetch} />
          </Table.Cell>
          <Table.Cell>
            {billPayExists ? (
              <WinRedChip variant="purple" onClick={_expandItem}>
                Bill-Pay
                <Icon className="ml-2" color="purple" icon={isExpanded ? 'minus-circle' : 'plus-circle'} size="lg" />
              </WinRedChip>
            ) : null}
          </Table.Cell>
          <Table.Cell>
            <GrossResultsDisplay type={type} pageGrossResults={pageGrossResults} />
          </Table.Cell>
          <Table.Cell textAlign="center" noClick>
            <PagesDropdownMenu dropdownAction={dropdownAction} item={item} profileCandidate={profileCandidate} />
          </Table.Cell>
        </Table.Row>
        {isExpanded && (
          <TableRowExpanded fluid className="p-0">
            <ExpandedRowBody item={item} />
          </TableRowExpanded>
        )}
      </Fragment>
    );
  });

  return (
    <Table data-testid="my-pages-index" className="pages-index-desktop">
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell className="d-none d-md-table-cell" />
          <Table.HeaderCell className="d-none d-md-table-cell" />
          <Table.HeaderCell>Page Information</Table.HeaderCell>
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell>
            {showBulkTagIcon ? (
              <div
                onClick={toggleBulkAddTag}
                className="border border-mystic inline-flex rounded items-center cursor-pointer bg-catskill-white p-0.5"
              >
                <Icon icon="tag" className="w-3.5 h-3.5 my-0 mr-1" />
                <span className="block mr-2">Bulk Add</span>
              </div>
            ) : null}
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>
            Results&nbsp;
            <ToolTip
              tooltip="This includes all money raised from this page (landing page, upsells, recurring, money pledge) to you and other candidates."
              className="tooltip-info"
            >
              <Icon icon="info-circle"></Icon>
            </ToolTip>
          </Table.HeaderCell>
          <Table.HeaderCell className="d-none d-md-table-cell" />
        </Table.Row>
      </Table.Head>
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
}

PagesTableDesktop.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
  dropdownAction: PropTypes.func.isRequired,
  expandItem: PropTypes.func.isRequired,
  expandedRows: PropTypes.object.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  uiFilter: PropTypes.array.isRequired,
  toggleBulkAddTag: PropTypes.func.isRequired,
};

export default PagesTableDesktop;
