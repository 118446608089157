import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';
import { HistoryTabTableMobile, HistoryTabTableDesktop } from './list-body';
import { EmptyList, Pagination } from 'modules/common';

export const HistoryTagsListBody = ({ openMainDrawer, data, refetch, profileCandidate }) => {
  const [filter, setFilter] = useState({
    profileCandidate: profileCandidate.organization_revv_uid,
    limit: 25,
    page: 1,
  });
  const onChangeFilter = ({ target: { name, value } }) => {
    let newFilter = { ...filter, [name]: value };
    setFilter(newFilter);
    let variables = newFilter;
    refetch(variables);
  };
  const clearFilter = () => {
    let newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    let variables = newFilter;
    refetch(variables);
  };
  const { current_page, page_count, total_count, results } = data;

  if (results?.length === 0 || !results) {
    return <EmptyList text="No Page Tag Activity" icon="tag" />;
  }

  return (
    <div className="mt-3">
      <Breakpoint small down>
        <HistoryTabTableMobile items={results} openMainDrawer={openMainDrawer} />
      </Breakpoint>
      <Breakpoint medium up>
        <HistoryTabTableDesktop items={results} openMainDrawer={openMainDrawer} />
      </Breakpoint>
      <Pagination
        currentPage={current_page}
        pageCount={page_count}
        totalCount={total_count}
        setPage={onChangeFilter}
        filter={filter}
        results={results}
        clearFilter={clearFilter}
      />
    </div>
  );
};

HistoryTagsListBody.propTypes = {
  openMainDrawer: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  profileCandidate: PropTypes.object.isRequired,
};
