import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, IndexTableContextMenu, Table, Typography } from 'modules/common';
import { Dropdown } from 'react-bootstrap';
import { PageTagItem } from '../../../../page-tag.component';
import { convertEpochTime } from 'core/utilities';

export const ActiveTabTableDesktop = ({ items, dropdownAction }) => {
  const tableBody = items?.map(item => {
    const { revv_uid, name, color, description, created_at, creator, subTags } = item;

    return (
      <Fragment key={revv_uid}>
        <Table.Row key={revv_uid} className="p-0" role="link" tabIndex="0">
          <Table.Cell>
            <div className="flex justify-start items-center">
              <PageTagItem data={{ name, color }} />
            </div>
          </Table.Cell>
          <Table.Cell>
            <Button
              skinnyButton
              square
              variant="alt"
              className="break-normal"
              onClick={() => dropdownAction('manage', item)}
            >
              Manage {subTags ? `(${subTags?.length})` : ''}
            </Button>
          </Table.Cell>
          <Table.Cell>
            <Typography>{description ? description : '-'}</Typography>
          </Table.Cell>
          <Table.Cell>
            {convertEpochTime(created_at)} <br />
            {creator && `by ${creator}`}
          </Table.Cell>
          <Table.Cell textAlign="center" noClick>
            <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
              <Dropdown.Item eventKey="manage">Manage</Dropdown.Item>
              <Dropdown.Item eventKey="archive">Archive</Dropdown.Item>
            </IndexTableContextMenu>
          </Table.Cell>
        </Table.Row>
      </Fragment>
    );
  });

  return (
    <Table data-testid="my-pages-index" className="pages-index-desktop">
      <Table.Head className="manage-tabs-header">
        <Table.Row>
          <Table.HeaderCell>Tag Name</Table.HeaderCell>
          <Table.HeaderCell>Sub-Tags</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Head>
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
};

ActiveTabTableDesktop.propTypes = {
  items: PropTypes.array.isRequired,
  dropdownAction: PropTypes.func.isRequired,
};
