import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ListFilter,
  WinRedFormSelect,
  dateRangeFilterUi,
  searchFilterUi,
  dropdownFilterUi,
  WinRedSelect,
  pageTagFilterUi,
} from 'modules/common';
import { TagMenuSelector } from './page-tags/tag-menu-selector.component';
import { Form } from 'react-bootstrap';

const STATUS_OPTIONS = [
  { value: '', label: '' },
  { value: 'live', label: 'Live' },
  { value: 'draft', label: 'Draft' },
  { value: 'password_protected', label: 'Password Protected' },
  { value: 'archived', label: 'Archived' },
];

const TAGS_PREDICATE_OPTIONS = [
  { value: 'OR', label: 'OR' },
  { value: 'AND', label: 'AND' },
];

export const mapFilterToUi = (filter, navTabs, feesOptions) => {
  let uiFilter = [];
  let numberOfFilters = 0;

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: navTabs,
    filterKey: 'activeTabKey',
    label: 'Page Type',
    defaultValue: 'all',
  });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    defaultValue: 'all',
    dropdownOptions: feesOptions,
    filterKey: 'fees',
    label: 'Bill-Pay',
  });

  [uiFilter, numberOfFilters] = dateRangeFilterUi({ filter, uiFilter, numberOfFilters });
  [uiFilter, numberOfFilters] = searchFilterUi({ filter, uiFilter, numberOfFilters });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: STATUS_OPTIONS,
    filterKey: 'status',
    label: 'Status',
  });
  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: TAGS_PREDICATE_OPTIONS,
    filterKey: 'tagsPredicate',
    label: 'Tags Predicate',
  });

  [uiFilter, numberOfFilters] = pageTagFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'tags',
    defaultValue: [],
  });

  return [uiFilter, numberOfFilters];
};

const selectedDropdownOption = (options, filter) => options.find(o => o.value === filter);

function PagesFilter({
  filter,
  onChangeFilter,
  clearFilter,
  submitFilter,
  numberOfFilters,
  feesOptions,
  filterSearchToolTip,
  refetch,
}) {
  const [showPageTagMenu, setShowPageTagMenu] = useState(false);
  const togglePageTagMenu = () => {
    setShowPageTagMenu(prevState => !prevState);
  };

  return (
    <ListFilter
      submitFilter={submitFilter}
      clearFilter={clearFilter}
      onChangeFilter={onChangeFilter}
      filter={filter}
      searchPlaceholder="Pages"
      numberOfFilters={numberOfFilters}
      filterSearchToolTip={filterSearchToolTip}
    >
      <WinRedFormSelect
        title="Status"
        name="status"
        onChange={onChangeFilter}
        value={selectedDropdownOption(STATUS_OPTIONS, filter.status)}
        options={STATUS_OPTIONS}
      />

      <WinRedFormSelect
        title="Bill-Pay"
        name="fees"
        onChange={onChangeFilter}
        value={selectedDropdownOption(feesOptions, filter.fees)}
        options={feesOptions}
      />
      <Form.Group>
        <WinRedSelect
          isMulti
          title="Tags"
          name="tags"
          onChange={onChangeFilter}
          options={[]}
          value={filter.tags}
          hideMenu
          noSearch
          onMenuOpen={() => setShowPageTagMenu(true)}
        />
      </Form.Group>
      {showPageTagMenu ? (
        <div className="relative">
          <TagMenuSelector
            isOpen={showPageTagMenu}
            toggleMenu={togglePageTagMenu}
            onChangeFilter={onChangeFilter}
            existingTags={filter.tags}
            onPageRefetch={refetch}
            isDropdown
          />
        </div>
      ) : null}
      <WinRedFormSelect
        title="Tags Predicate"
        name="tagsPredicate"
        onChange={onChangeFilter}
        value={selectedDropdownOption(TAGS_PREDICATE_OPTIONS, filter.tagsPredicate)}
        options={TAGS_PREDICATE_OPTIONS}
      />
    </ListFilter>
  );
}

PagesFilter.propTypes = {
  filter: PropTypes.object,
  numberOfFilters: PropTypes.number.isRequired,
  clearFilter: PropTypes.func.isRequired,
  submitFilter: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PagesFilter;
