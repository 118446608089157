import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './nested-drawer.scss';

export function NestedDrawer({
  isLeft = false,
  open,
  children,
  className = '',
  setbackdropRef,
  footer,
  nestLevel,
  drawerPadding = true,
}) {
  return ReactDOM.createPortal(
    <>
      <div
        ref={setbackdropRef}
        className={classnames({
          'nested-drawer-open-back': open,
          'full-width-back': nestLevel === 0,
          'nested-width-back': nestLevel === 1,
          'double-nested-width-back': nestLevel === 2,
        })}
      ></div>
      <div
        className={classnames('nested-drawer', className, {
          open: open,
          'drawer-padding': drawerPadding,
          'nested-drawer-left': isLeft,
          'nested-drawer-right': !isLeft,
          'full-width': nestLevel === 0,
          'nested-width': nestLevel === 1,
          'double-nested-width': nestLevel === 2,
        })}
      >
        <div className="nested-drawer-content">{children}</div>
        {footer ? <div className="nested-drawer-footer">{footer}</div> : null}
      </div>
    </>,
    document.body
  );
}

NestedDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isLeft: PropTypes.bool,
  children: PropTypes.any.isRequired,
  footer: PropTypes.any,
  nestLevel: PropTypes.number.isRequired,
  drawerPadding: PropTypes.bool,
};
