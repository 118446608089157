import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/common';

export const PageTagItem = ({ data, isFilterTag = false, onRemove }) => {
  return (
    <span
      className="page-tag-pill border-2 inline-flex justify-start items-center text-left min-h-7 px-2 rounded py-0.5"
      style={{ background: `${data?.color}1A`, borderColor: `${data?.color}4D` }}
    >
      <span style={{ color: '#627890' }}>
        <span className="italic">{data?.parent_tag_name ? `${data?.parent_tag_name}:: ` : ''}</span>
        <span className="font-medium">{data?.name}</span>
      </span>

      {isFilterTag ? <Icon icon="times" onClick={onRemove} className="ml-2 cursor-pointer mb-0" /> : null}
    </span>
  );
};

PageTagItem.propTypes = {
  data: PropTypes.object,
  isFilterTag: PropTypes.bool,
  onRemove: PropTypes.func,
};
