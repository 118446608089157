import { replaceSpacesWithDashes } from 'core/utilities';

export const formatTagDataForSever = (data, isEdit = false) => {
  let formattedTagData = {
    name: data.name,
    description: data.description,
    color: data.color,
  };
  if (isEdit) formattedTagData.revv_uid = data.revv_uid;
  if (data.parentTagId) formattedTagData.parent_tag_revv_uid = data.parentTagId;
  return formattedTagData;
};

export const formatTagsForPageSever = (data, isFromFilter = false) => {
  let formattedData = isFromFilter ? convertTagOptionsToObjects(data) : data;

  formattedData = formattedData.map(tag => {
    let formattedTag = {
      revv_uid: tag.revv_uid,
    };
    if (tag.destroy) formattedTag.destroy = tag.destroy;
    return formattedTag;
  });
  return formattedData;
};

export const formatTagsForFilter = selectedTags => {
  return selectedTags.map(tag => {
    return {
      label: tag.name,
      value: tag,
    };
  });
};

export const createTagOptions = tags => {
  return tags.map(tag => {
    return {
      label: tag.name,
      value: replaceSpacesWithDashes(tag.name, true),
    };
  });
};

export const createTagSelectionItems = (selectedTags = [], parentTags) => {
  let _selectedTags = [...selectedTags];
  let existingSubTags = [];
  let _existingTags = _selectedTags?.map(tag => {
    if (!tag?.parent_tag) existingSubTags.push({ ...tag });
    return tag?.revv_uid;
  });
  let _selectionPool = [...parentTags, ...existingSubTags]?.sort((a, b) => {
    const aIncluded = _existingTags?.includes(a.revv_uid);
    const bIncluded = _existingTags?.includes(b.revv_uid);

    return aIncluded === bIncluded ? 0 : aIncluded ? -1 : 1;
  });
  return _selectionPool;
};

export const addUpdateOrRemoveTagInList = (tag, action, tagList) => {
  let newTagList = [];
  if (action === 'update') {
    newTagList = tagList?.map(item => {
      if (item.revv_uid === tag.revv_uid) return tag;
      return item;
    });
  } else if (action === 'remove') {
    newTagList = tagList?.filter(item => item.revv_uid !== tag.revv_uid);
  } else {
    newTagList = [...tagList, tag];
  }
  return newTagList;
};

export const convertTagOptionsToObjects = data => {
  let formattedData = data.map(option => {
    return option.value;
  });
  return formattedData;
};

export const _updateTagData = (tagList, newTag, oldTag) => {
  let newTagList = tagList.map(tag => {
    if (tag.revv_uid === newTag.revv_uid) return { ...newTag };
    if (tag.parent_tag_name === oldTag?.name) return { ...tag, parent_tag_name: newTag.name };
    return { ...tag };
  });
  return newTagList;
};

export const _archiveTagData = (tagList, archivedTag) => {
  let newTagList = tagList.filter(
    tag => tag.revv_uid !== archivedTag.revv_uid && tag.parent_tag_name !== archivedTag.name
  );
  return newTagList;
};

export const _updatePageTags = ({ tagList, tagToUpdate, updateAction, oldTagData }) => {
  let newTagList = [...tagList];
  if (updateAction === 'ARCHIVED') {
    newTagList = _archiveTagData(newTagList, tagToUpdate);
  }
  if (updateAction === 'UPDATED') {
    newTagList = _updateTagData(newTagList, tagToUpdate, oldTagData);
  }
  if (updateAction === 'CREATED') {
    newTagList = [...newTagList, tagToUpdate];
  }
  return newTagList;
};

export const updateTagState = ({ action, selectedTags, parentTags, isSubTag, actionTag, oldTag }) => {
  let existingTagsUpdated = false;
  let _selectedTags = [...selectedTags];
  let _parentTags = [...parentTags];
  if (isSubTag) {
    let isSubTagSelected = _selectedTags.some(tag => tag.revv_uid === actionTag.revv_uid);
    if (isSubTagSelected) {
      _selectedTags = _updatePageTags({ tagList: _selectedTags, updateAction: action, tagToUpdate: actionTag });
      existingTagsUpdated = true;
    }
    if (action === 'CREATED') {
      _parentTags = _parentTags.map(parent => {
        if (parent.name === actionTag.parent_tag_name) return { ...parent, has_child_tags: true };
        return { ...parent };
      });
    }
  } else {
    if (action === 'CREATED') {
      _parentTags = _updatePageTags({ tagList: _parentTags, updateAction: action, tagToUpdate: actionTag });
    } else {
      let isParentTagSelected = _selectedTags.some(
        tag =>
          tag.revv_uid === actionTag.revv_uid ||
          tag.parent_tag_name === oldTag?.name ||
          tag.parent_tag_name === actionTag.name
      );
      if (isParentTagSelected) {
        _selectedTags = _updatePageTags({
          tagList: _selectedTags,
          updateAction: action,
          tagToUpdate: actionTag,
          oldTagData: oldTag,
        });
        existingTagsUpdated = true;
      }
      _parentTags = _updatePageTags({ tagList: _parentTags, updateAction: action, tagToUpdate: actionTag });
    }
  }
  return { _selectedTags, _parentTags, existingTagsUpdated };
};

export const checkIfTagDataChanged = (currentData, originalData) => {
  return (
    currentData?.color === originalData?.color &&
    currentData?.name === originalData?.name &&
    currentData?.description === originalData?.description
  );
};

export const checkIfRequiredDataFilled = data => {
  return data?.color !== '' && data?.name !== '';
};

export const sortTagsByLevel = data => {
  return data.sort((a, b) => {
    if (a.parent_tag === b.parent_tag) {
      return 0;
    }
    return a.parent_tag ? -1 : 1;
  });
};
