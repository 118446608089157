import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Icon, SpinnerContainer, Typography, useToast, WinRedSearchInput } from 'modules/common';
import { PageTagItem } from './page-tag.component';
import './page-tags.scss';
import { defaultDrawerFields, pageTagColors, TAG_DRAWER_TYPES } from './fields';
import { PageTagDrawerContainer } from './drawers/page-tag-drawer.container';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CANDIDATE_BULK_ADD_PAGE_TAG,
  GET_CANDIDATE_PAGE_TAGS,
  GET_ORGANIZATION_PAGE_TAGS,
  ORGANIZATION_BULK_ADD_PAGE_TAG,
} from 'core/middleware/queries';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';
import {
  createTagOptions,
  createTagSelectionItems,
  formatTagsForFilter,
  formatTagsForPageSever,
  updateTagState,
} from './tags.tools';

const TagMenuContainer = ({ children, isOpen, onClose }) => {
  let tagMenuSelectorRef = useRef();
  const setTagMenuSelectorRef = node => (tagMenuSelectorRef = node);

  const handleClickOutsideTagMenu = event => {
    if (tagMenuSelectorRef && !tagMenuSelectorRef.contains(event.target)) {
      if (!isOpen) {
        onClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideTagMenu, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTagMenu, { capture: true });
    };
  });
  return <div ref={setTagMenuSelectorRef}>{children}</div>;
};

TagMenuContainer.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const TagMenuSelector = ({
  pageRevvUid,
  isOpen,
  toggleMenu,
  isDropdown = false,
  onChangeFilter,
  existingTags = [],
  isBulkAdd = false,
  updatePageTags,
  isMobile = false,
  onPageRefetch,
  isPageBuilder = false,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectionPool, setSelectionPool] = useState([]);
  const [tagDrawer, setTagDrawer] = useState(defaultDrawerFields);
  const [searchPool, setSearchPool] = useState([]);
  const [parentTags, setParentTags] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [parentTagView, setParentTagView] = useState({
    isSet: false,
    parent_name: '',
    sub_tags: [],
  });

  const setToast = useToast();
  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);

  const [timeoutId, setTimeoutId] = useState('');

  const initialVariables = useMemo(() => {
    return {
      organizationRevvUid: profileCandidate.organization_revv_uid,
    };
  }, [profileCandidate.organization_revv_uid]);

  const isTagDrawersOpen = useMemo(() => {
    return (
      tagDrawer[TAG_DRAWER_TYPES.MAIN_TAG].isOpen ||
      tagDrawer[TAG_DRAWER_TYPES.MANAGE_TAGS].isOpen ||
      tagDrawer[TAG_DRAWER_TYPES.SUB_TAG].isOpen
    );
  }, [tagDrawer]);

  const onDrawerChange = ({ name, value }, closeAnotherDrawer = false, drawerToClose) => {
    let newDrawerData = { ...tagDrawer, [name]: value };
    if (closeAnotherDrawer) {
      newDrawerData = { ...newDrawerData, [drawerToClose]: { ...tagDrawer[drawerToClose], isOpen: false } };
    }
    setTagDrawer(newDrawerData);
  };
  const onToggleDrawerOpen = name => {
    let value = { ...tagDrawer[name], isOpen: true };
    if (name === TAG_DRAWER_TYPES.MAIN_TAG) value.data = { name: '', color: pageTagColors[0], description: '' };
    onDrawerChange({ name, value });
  };

  const onMenuClose = () => {
    onChangeSearchText({ target: { value: '' } }, true);
    setParentTagView({ isSet: false, parent_name: '', sub_tags: [] });
    toggleMenu();
  };

  const [searchTags, { loading: searchTagsLoading, error: searchTagsError }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_PAGE_TAGS : GET_CANDIDATE_PAGE_TAGS,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const candidate = data.viewer.candidate || data.viewer.state_level_organization;
        if (parentTagView?.isSet) {
          setParentTagView({
            ...parentTagView,
            sub_tags: candidate.tags.results,
          });
        }
        setSearchPool(candidate.tags.results);
      },
    }
  );

  const [getParentTags, { loading, error }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_PAGE_TAGS : GET_CANDIDATE_PAGE_TAGS,
    {
      variables: {
        ...initialVariables,
        parentTag: true,
        limit: 25,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const candidate = data.viewer.candidate || data.viewer.state_level_organization;
        let _existingTags = isDropdown
          ? existingTags?.map(item => {
              return item?.value;
            })
          : existingTags;
        setParentTags(candidate?.tags?.results);
        setSelectedTags(_existingTags || []);
        setSelectionPool(createTagSelectionItems(_existingTags, candidate?.tags?.results));
      },
    }
  );

  const onUpdateTagState = ({ actionTag, action, oldTag, isSubTag = false }) => {
    const { _selectedTags, _parentTags, existingTagsUpdated } = updateTagState({
      action,
      selectedTags,
      parentTags,
      isSubTag,
      actionTag,
      oldTag,
    });
    if (isDropdown && existingTagsUpdated) {
      onChangeFilter({ target: { name: 'tags', value: createTagOptions(_selectedTags) } });
    }
    if (!isDropdown && existingTagsUpdated) updatePageTags(_selectedTags);
    setParentTags(_parentTags);
    setSelectedTags(_selectedTags);
    setSelectionPool(createTagSelectionItems(_selectedTags, _parentTags));
  };

  const [applyTagsToPage, { loading: applyTagsLoading }] = useMutation(
    isOrganization ? ORGANIZATION_BULK_ADD_PAGE_TAG : CANDIDATE_BULK_ADD_PAGE_TAG
  );

  const handleParentSearch = useCallback(
    ({ target: { value } }) => {
      const filteredArray = searchPool.filter(tag => tag.name.toLowerCase().includes(value.toLowerCase()));
      setParentTagView({ ...parentTagView, sub_tags: filteredArray });
    },
    [parentTagView, searchPool]
  );

  const onChangeSearchText = useCallback(
    ({ target: { value } }, noServerCall = false) => {
      if (parentTagView?.isSet) {
        let _value = value.replace(`${parentTagView?.parent_name}:: `, '');
        if (value === `${parentTagView?.parent_name}::`) _value = '';
        setSearchText(_value);
        handleParentSearch({ target: { value: _value } });
        return;
      }
      if (noServerCall) return setSearchText(value);
      setSearchText(value);

      setSearchPool([]);

      if (timeoutId) clearTimeout(timeoutId);
      const _id = setTimeout(() => {
        if (value && value.length > 2) searchTags({ variables: { ...initialVariables, search: value, limit: 25 } });
      }, 400);

      setTimeoutId(_id);
    },
    [initialVariables, searchTags, timeoutId, parentTagView?.isSet, handleParentSearch, parentTagView?.parent_name]
  );

  const handleClearSearch = useCallback(() => {
    setParentTagView({ isSet: false, parent_name: '', sub_tags: [] });
    setSearchPool([]);
    setSelectionPool(createTagSelectionItems(selectedTags, parentTags));
    setSearchText('');
  }, [parentTags, selectedTags]);

  const onApplyTags = async () => {
    if (selectedTags.filter(tag => !tag.destroy).length > 3) {
      setToast({ isError: true, message: 'Pages can only have up to 3 tags.' });
      return;
    }
    const variables = {
      pageRevvUid,
      organizationRevvUid: profileCandidate.organization_revv_uid,
      tags: formatTagsForPageSever(selectedTags),
    };
    try {
      const res = await applyTagsToPage({ variables });
      const { errors } = res;
      if (errors) {
        return setToast({ message: errors, isError: true });
      }
      setToast({ message: 'Your Tags have been applied to the page.' });
      if (isPageBuilder) {
        setSelectedTags(
          selectedTags.map(tag => {
            let formattedTag = { ...tag };
            if (tag._isNew) delete formattedTag._isNew;
            return formattedTag;
          })
        );
        updatePageTags(selectedTags.filter(tag => !tag.destroy));
      } else {
        onPageRefetch();
      }
      onMenuClose();
    } catch (error) {
      setToast({ message: error.message, isError: true });
      console.error(error.message);
    }
  };
  const onApplyTagFilter = () => {
    onMenuClose();
    return onChangeFilter({ target: { name: 'tags', value: formatTagsForFilter(selectedTags) } });
  };
  const handleOnApply = async () => {
    isDropdown ? onApplyTagFilter() : onApplyTags();
  };

  const handleOnSelection = useCallback(
    item => {
      let isTagSelected = selectedTags?.find(tag => tag.revv_uid === item.revv_uid);
      if (isTagSelected) {
        let newTags = isTagSelected._isNew
          ? selectedTags.filter(tag => tag.revv_uid !== item.revv_uid)
          : selectedTags.map(tag => {
              if (tag.revv_uid === item.revv_uid) {
                return { ...tag, destroy: tag.destroy ? false : true };
              }

              return { ...tag };
            });
        setSelectedTags([...newTags]);
      } else {
        setSelectedTags([...selectedTags, { ...item, _isNew: true }]);
      }
    },
    [selectedTags]
  );

  const handleOnViewSubTags = parentTag => {
    setParentTagView({
      isSet: true,
      parent_name: parentTag.name,
      sub_tags: [],
    });
    searchTags({ variables: { ...initialVariables, parentTagRevvUid: parentTag.revv_uid, limit: 100 } });
  };

  useEffect(() => {
    if (isOpen) {
      if (!isInitialized) {
        getParentTags();
        setIsInitialized(true);
      } else {
        setSelectionPool(createTagSelectionItems(selectedTags, parentTags));
      }
    }
    // eslint-disable-next-line
  }, [isOpen, getParentTags, isInitialized]);

  if (error || searchTagsError) {
    setToast({ isError: true, message: error || searchTagsError });
  }

  const selectedList = useMemo(() => {
    if (searchText === '' && !parentTagView?.isSet) {
      return selectionPool;
    } else if (parentTagView?.isSet) {
      return parentTagView?.sub_tags;
    } else {
      return searchPool;
    }
  }, [searchPool, selectionPool, searchText, parentTagView]);

  return (
    <TagMenuContainer onClose={onMenuClose} isOpen={isTagDrawersOpen}>
      {isOpen ? (
        <div
          className={`page-tag-menu-selector ${isMobile ? 'mobile-position' : 'desktop-position'} ${
            isDropdown ? 'page-tag-menu-static' : 'page-tag-menu-overlay'
          }`}
        >
          <div className="search-bar flex justify-between items-center pr-4">
            <WinRedSearchInput
              classNameContainer="!border-none"
              classNameWrapper="w-full"
              onChange={onChangeSearchText}
              placeholder="Search..."
              name="searchText"
              value={parentTagView?.isSet ? `${parentTagView?.parent_name}:: ${searchText}` : searchText}
              removeBorder
            />
            {searchText !== '' || parentTagView?.isSet ? (
              <Icon icon="times-circle" onClick={handleClearSearch} />
            ) : null}
          </div>
          <div className="list-body h-48">
            {loading || applyTagsLoading || searchTagsLoading ? (
              <SpinnerContainer />
            ) : selectedList?.length === 0 ? (
              <div className="flex justify-center items-center w-full h-full">
                <Typography className="text-center">
                  {parentTagView?.isSet ? 'No matching Sub-tags available' : `There are no tags available`}
                </Typography>
              </div>
            ) : (
              <>
                {selectedList.map(item => {
                  return (
                    <div key={item.revv_uid} className="flex items-center justify-between my-1">
                      <div className="flex items-center my-1.5">
                        <Checkbox
                          name="checked"
                          value={selectedTags?.some(tag => tag.revv_uid === item.revv_uid && !tag.destroy)}
                          onChange={() => handleOnSelection(item)}
                        />
                        <PageTagItem data={item} />
                      </div>
                      {!item?.parent_tag_name && item?.has_child_tags ? (
                        <div
                          onClick={() => handleOnViewSubTags(item)}
                          className="h-7 w-7 border border-catskill flex justify-center items-center rounded-sm "
                        >
                          <Icon icon="chevron-square-right" />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {isBulkAdd ? null : (
            <div className="tag-options">
              <div className="btn-item p-3.5 w-full hover:bg-catskill-white transition-colors ">
                <Typography
                  className="cursor-pointer mb-0"
                  onClick={() => onToggleDrawerOpen(TAG_DRAWER_TYPES.MAIN_TAG)}
                  variant="bodyMedium"
                  color="mirage"
                >
                  Create New Tag
                </Typography>
              </div>
              <div className="btn-item p-3.5 w-full hover:bg-catskill-white transition-colors">
                <Typography
                  className="cursor-pointer mb-0"
                  onClick={() => onToggleDrawerOpen(TAG_DRAWER_TYPES.MANAGE_TAGS)}
                  variant="bodyMedium"
                  color="mirage"
                >
                  Manage Tags
                </Typography>
              </div>
            </div>
          )}

          <div className="footer flex items-center justify-end">
            <Button variant="alt" skinnyButton onClick={onMenuClose} className="mr-2">
              Cancel
            </Button>
            <Button skinnyButton onClick={handleOnApply} disabled={isDropdown ? false : selectedTags?.length === 0}>
              Apply
            </Button>
          </div>
        </div>
      ) : null}
      {isOpen ? (
        <PageTagDrawerContainer
          parentTags={parentTags}
          updateParentTags={setParentTags}
          onUpdateTagState={onUpdateTagState}
          drawer={tagDrawer}
          onChange={onDrawerChange}
          existingTags={existingTags}
          isDropdown={isDropdown}
          onChangeSelectFilter={onChangeFilter}
          onPageRefetch={onPageRefetch}
        />
      ) : null}
    </TagMenuContainer>
  );
};

TagMenuSelector.propTypes = {
  pageRevvUid: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isDropdown: PropTypes.bool,
  onChangeFilter: PropTypes.func,
  existingTags: PropTypes.array,
  isBulkAdd: PropTypes.bool,
  updatePageTags: PropTypes.func,
  isMobile: PropTypes.bool,
  onPageRefetch: PropTypes.func,
  isPageBuilder: PropTypes.bool,
};
