import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, SpinnerContainer, WinRedSearchInput } from 'modules/common';
import { Breakpoint } from 'react-socks';
import { SubTagsTableMobile, SubTagsTableDesktop } from './list-body';

export const SubTagsListBody = ({
  drawer,
  data,
  openSubDrawer,
  getSubTags,
  setSearchPool,
  subSearchText,
  setSubSearchText,
  loading,
  setArchiveModal,
}) => {
  const [timeoutId, setTimeoutId] = useState('');

  const onSearchSubTags = useCallback(
    async value => {
      let variables = {
        search: value,
      };
      await getSubTags(variables);
    },
    [getSubTags]
  );

  const onChangeSearchText = useCallback(
    ({ target: { value } }, noServerCall = false) => {
      if (noServerCall) return setSubSearchText(value);
      setSubSearchText(value);

      setSearchPool({});

      if (timeoutId) clearTimeout(timeoutId);
      const _id = setTimeout(() => {
        if (value && value.length > 2) onSearchSubTags(value);
      }, 400);

      setTimeoutId(_id);
    },
    [timeoutId, setSearchPool, setSubSearchText, onSearchSubTags]
  );
  const dropdownAction = (eventKey, subTag) => {
    switch (eventKey) {
      case 'edit':
        openSubDrawer({ isEdit: true, data: subTag });
        break;
      case 'archive':
        setArchiveModal({
          display: true,
          selectedTag: subTag,
        });
        break;
      default:
        break;
    }
  };

  const clearSearch = () => {
    setSearchPool({});
    setSubSearchText('');
  };

  return (
    <div>
      {drawer?.data?.subTags?.results?.length > 0 ? (
        <div className="search-border flex bg-white items-center rounded-full border-catskill border-2 pr-5">
          <WinRedSearchInput
            classNameWrapper="w-full"
            removeBorder
            value={subSearchText}
            onChange={onChangeSearchText}
            placeholder="Search Sub-tags..."
          />
          {subSearchText.length > 0 ? (
            <Icon className="cursor-pointer" onClick={clearSearch} icon="times-circle" size="lg" />
          ) : null}
        </div>
      ) : null}

      {loading ? (
        <SpinnerContainer />
      ) : data?.results?.length > 0 ? (
        <div className="mt-3">
          <Breakpoint small down>
            <SubTagsTableMobile items={data?.results} dropdownAction={dropdownAction} />
          </Breakpoint>
          <Breakpoint medium up>
            <SubTagsTableDesktop items={data.results} dropdownAction={dropdownAction} />
          </Breakpoint>
        </div>
      ) : null}
    </div>
  );
};

SubTagsListBody.propTypes = {
  drawer: PropTypes.object.isRequired,
  data: PropTypes.object,
  openSubDrawer: PropTypes.func.isRequired,
  getSubTags: PropTypes.func.isRequired,
  setSearchPool: PropTypes.func.isRequired,
  subSearchText: PropTypes.string.isRequired,
  setSubSearchText: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setArchiveModal: PropTypes.func.isRequired,
};
