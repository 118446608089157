import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'modules/common';
import { PageTagItem } from '../../../../page-tag.component';
import { convertEpochTime } from 'core/utilities';
import { TAG_ACTIVITY_TYPE_MAP } from 'modules/pages/pages/page-tags/fields';

export const HistoryTabTableDesktop = ({ items }) => {
  const tableBody = items?.map((item, index) => {
    const { activity_type, created_at, pages_count, tag_names, creator_email } = item;

    return (
      <Fragment key={index}>
        <Table.Row className="p-0" role="link" tabIndex="0">
          <Table.Cell>{TAG_ACTIVITY_TYPE_MAP[activity_type]}</Table.Cell>
          <Table.Cell>
            <div className="tag-list flex flex-wrap text-nowrap gap-y-1.5 gap-x-2">
              {tag_names.map((tag, index) => {
                return <PageTagItem key={index} data={{ name: tag, color: '#aaaaaa' }} />;
              })}
            </div>
          </Table.Cell>
          <Table.Cell>
            {pages_count} {`Page${pages_count === 1 ? '' : 's'}`}
          </Table.Cell>
          <Table.Cell>
            {creator_email ? creator_email : null}
            <br />
            {convertEpochTime(created_at)}
          </Table.Cell>
        </Table.Row>
      </Fragment>
    );
  });

  return (
    <Table data-testid="my-pages-index" className="pages-index-desktop">
      <Table.Head className="manage-tabs-header">
        <Table.Row>
          <Table.HeaderCell>Activity</Table.HeaderCell>
          <Table.HeaderCell>Tag(s)</Table.HeaderCell>
          <Table.HeaderCell>Number of Pages</Table.HeaderCell>
          <Table.HeaderCell>By</Table.HeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
};

HistoryTabTableDesktop.propTypes = {
  items: PropTypes.array.isRequired,
};
