import React from 'react';
import classnames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { Icon, Typography } from 'modules/common';

export function DrawerHeader({ title, subtitle, toggleOpen, icon = 'times', children, nextRow, classNameContainer }) {
  return (
    <Container
      fluid
      className={classnames({
        'p-2': !classNameContainer,
        [classNameContainer]: classNameContainer,
      })}
    >
      <Row className="d-flex align-items-center">
        <Col
          xs="auto"
          className={classnames('mt-1', { pointer: !!toggleOpen })}
          onClick={icon === 'times' ? toggleOpen : null}
        >
          <Icon icon={icon} color="gray" size="2x" />
        </Col>
        <Col className="p-0">
          {children ? (
            children
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              <Typography variant="h2" className="mb-0">
                {title}
              </Typography>
              {subtitle ? <Typography className="mb-0">{subtitle}</Typography> : null}
            </div>
          )}
        </Col>
      </Row>
      {nextRow ? nextRow : null}
    </Container>
  );
}
