import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IndexTableContextMenu, Table, Typography } from 'modules/common';
import { Dropdown } from 'react-bootstrap';
import { PageTagItem } from '../../../page-tag.component';
import { convertEpochTime } from 'core/utilities';

export const SubTagsTableDesktop = ({ items, dropdownAction }) => {
  const tableBody = items.map(item => {
    const { revv_uid, name, color, description, created_at, creator } = item;

    return (
      <Fragment key={revv_uid}>
        <Table.Row key={revv_uid} className="p-0" role="link" tabIndex="0">
          <Table.Cell>
            <div className="flex justify-start items-center">
              <PageTagItem data={{ name, color }} />
            </div>
          </Table.Cell>
          <Table.Cell>
            <Typography>{description ? description : '-'}</Typography>
          </Table.Cell>
          <Table.Cell>
            {convertEpochTime(created_at)} <br />
            {creator && `by ${creator}`}
          </Table.Cell>
          <Table.Cell textAlign="center" noClick>
            <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
              <Dropdown.Item eventKey="edit">Edit</Dropdown.Item>
              <Dropdown.Item eventKey="archive">Archive</Dropdown.Item>
            </IndexTableContextMenu>
          </Table.Cell>
        </Table.Row>
      </Fragment>
    );
  });

  return (
    <Table data-testid="sub-tag-index" className="sub-tag-index">
      <Table.Head className="main-tag-table-header">
        <Table.Row>
          <Table.HeaderCell>Sub-tag Name</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Head>
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
};

SubTagsTableDesktop.propTypes = {
  items: PropTypes.array.isRequired,
  dropdownAction: PropTypes.func.isRequired,
};
