import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DrawerHeader, ExternalLink, NestedDrawer, Typography, useToast } from 'modules/common';
import { PageTagForm } from '../page-tag-form.component';
import { useMutation } from '@apollo/client';
import {
  addUpdateOrRemoveTagInList,
  checkIfRequiredDataFilled,
  checkIfTagDataChanged,
  formatTagDataForSever,
} from '../../tags.tools';
import { SAVE_CANDIDATE_PAGE_TAG, SAVE_ORGANIZATION_PAGE_TAG } from 'core/middleware/queries';
import { ValidationBlock } from 'core/validation';

export const SubTagDrawer = ({
  drawer,
  toggleOpen,
  onChange,
  isManageTagsDrawerOpen,
  isOrganization,
  profileCandidate,
  mainTagDrawer,
  onChangeMainDrawer,
  setTagsUpdated,
  onUpdateTagState,
}) => {
  const [initialTagData, setInitialTagData] = useState(drawer?.data);
  const [isInitialized, setIsInitialized] = useState(false);

  let backdropRef = useRef();
  const setbackdropRef = node => (backdropRef = node);
  const handleOnClose = () => {
    setIsInitialized(false);
    toggleOpen();
  };

  const setToast = useToast();

  const validationBlock = createRef();

  const disableSaveButton = useMemo(() => {
    return checkIfTagDataChanged(drawer?.data, initialTagData) || !checkIfRequiredDataFilled(drawer?.data);
  }, [drawer?.data, initialTagData]);

  const [saveSubTag] = useMutation(isOrganization ? SAVE_ORGANIZATION_PAGE_TAG : SAVE_CANDIDATE_PAGE_TAG);

  const handleClickOutside = event => {
    const clickedBackdrop = drawer?.isOpen && backdropRef && backdropRef?.contains(event.target);
    if (clickedBackdrop) {
      handleOnClose();
    }
  };

  const onChangeData = useCallback(
    ({ target: { name, value } }) => {
      let newValue = {
        ...drawer,
        data: {
          ...drawer?.data,
          [name]: value,
        },
      };
      onChange(newValue);
    },
    [drawer, onChange]
  );

  const onChangeSubtags = useCallback(
    ({ target: { value } }, total_count) => {
      let newValue = {
        ...mainTagDrawer,
        data: {
          ...mainTagDrawer?.data,
          subTags: { ...mainTagDrawer?.data?.subTags, total_count, results: value },
        },
      };
      onChangeMainDrawer(newValue);
    },
    [onChangeMainDrawer, mainTagDrawer]
  );

  const onSaveSubTag = async () => {
    if (validationBlock.current.errors()) {
      setToast({
        message: 'Please correct the errors on this page before saving!',
        isError: true,
      });
      return;
    }
    const variables = {
      tag: formatTagDataForSever({ ...drawer?.data, parentTagId: mainTagDrawer?.data?.revv_uid }, true),
      organizationRevvUid: profileCandidate.organization_revv_uid,
    };
    try {
      const res = await saveSubTag({ variables });
      const candidate = res.data.candidateCreateOrUpdateTag || res.data.organizationCreateOrUpdateTag;
      const { errors, tag } = candidate;
      if (errors) {
        setToast({ isError: true, message: errors });
        return;
      }
      let subTagResults = mainTagDrawer?.data?.subTags?.results;
      let isResultsAtLimit = subTagResults?.length === 25;
      if (isResultsAtLimit && !drawer?.isEdit) subTagResults.pop();
      onChangeSubtags(
        {
          target: {
            value: addUpdateOrRemoveTagInList(
              { ...tag, is_favorite: drawer?.data?.is_favorite },
              drawer?.isEdit ? 'update' : 'create',
              subTagResults
            ),
          },
        },
        drawer?.isEdit ? subTagResults.length : subTagResults.length + 1
      );
      let updateStateData = {
        actionTag: tag,
        action: drawer?.isEdit ? 'UPDATED' : 'CREATED',
        isSubTag: true,
      };
      if (drawer?.isEdit) {
        updateStateData.oldTag = drawer?.data;
        setTagsUpdated(true);
      }
      setToast({ message: `${tag.name} Sub-tag has been ${drawer?.isEdit ? 'edited' : 'created'}.` });
      onUpdateTagState(updateStateData);
    } catch (error) {
      console.error(error);
      setToast({ isError: true, message: error });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  useEffect(() => {
    if (drawer?.isOpen) {
      if (!isInitialized) {
        setInitialTagData(drawer?.data);
        setIsInitialized(true);
      }
    }
  }, [drawer?.data, drawer?.isOpen, isInitialized]);

  return (
    <NestedDrawer
      className="sub-tag-drawer"
      setbackdropRef={setbackdropRef}
      open={drawer?.isOpen}
      toggleOpen={handleOnClose}
      icon="times"
      nestLevel={isManageTagsDrawerOpen ? 2 : 1}
      footer={
        <div className="p-3">
          <Button variant="alt" onClick={handleOnClose} className="mr-3">
            Cancel
          </Button>
          <Button variant="success" disabled={disableSaveButton} onClick={onSaveSubTag}>
            {drawer?.isEdit ? 'Save' : 'Create'} Sub-tag
          </Button>
        </div>
      }
    >
      <DrawerHeader toggleOpen={handleOnClose}>
        <Typography variant="h2" className="mb-0">
          {drawer?.isEdit ? 'Editing Sub-tag' : 'Create New Sub-tag'}
        </Typography>
        <Typography className="mb-0">
          Sub-tags provide additional categorization within a main tag, allowing for more precise and relevant
          grouping.&nbsp;
          <ExternalLink href="https://support.winred.com/en/articles/9740243-page-tags">Learn More</ExternalLink>
        </Typography>
      </DrawerHeader>
      {drawer?.isOpen ? (
        <div className="drawer-body">
          <ValidationBlock ref={validationBlock}>
            <PageTagForm data={drawer?.data} onChange={onChangeData} isSubTag />
          </ValidationBlock>
        </div>
      ) : null}
    </NestedDrawer>
  );
};

SubTagDrawer.propTypes = {
  drawer: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isManageTagsDrawerOpen: PropTypes.bool.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  mainTagDrawer: PropTypes.object.isRequired,
  onChangeMainDrawer: PropTypes.func.isRequired,
  setTagsUpdated: PropTypes.func.isRequired,
  onUpdateTagState: PropTypes.func.isRequired,
};
