import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { convertEpochTime } from 'core/utilities';
import { Typography } from 'modules/common';
import { TAG_ACTIVITY_TYPE_MAP } from 'modules/pages/pages/page-tags/fields';
import { PageTagItem } from 'modules/pages/pages/page-tags/page-tag.component';
import { Row } from 'react-bootstrap';

export const HistoryTabTableMobile = ({ items }) => {
  if (!items) return null;

  return items?.map(item => {
    const { activity_type, created_at, pages_count, revv_uid, tag_names, creator_email } = item;

    return (
      <Fragment key={revv_uid}>
        <div className="list-item-card" tabIndex="0" role="link">
          <Row className="no-gutters flex flex-col">
            <div className="flex justify-between items-start">
              <div className="flex-flex-col">
                <Typography>{TAG_ACTIVITY_TYPE_MAP[activity_type]}</Typography>
                <Typography>
                  {pages_count} {`Page${pages_count === 1 ? '' : 's'}`}
                </Typography>
              </div>
              <Typography>
                {creator_email && `by ${creator_email}`} <br />
                {convertEpochTime(created_at)}
              </Typography>
            </div>
            <div className="tag-list flex flex-wrap text-nowrap gap-y-1.5 gap-x-2">
              {tag_names.map(tag => {
                return <PageTagItem key={tag.revv_uid} data={{ name: tag, color: '#aaaaaa' }} />;
              })}
            </div>
          </Row>
        </div>
      </Fragment>
    );
  });
};

HistoryTabTableMobile.propTypes = {
  items: PropTypes.array.isRequired,
};
