import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, SketchColorPicker, Typography } from 'modules/common';
import { ColorCircle } from './color-circle.component';

function CircleColorPicker({ value, onChange, onHide, disabled, presetColors }) {
  const [showCustomColor, setShowCustomColor] = useState(false);
  const onChangeColor = e => {
    onChange(e);
    onHide();
    setShowCustomColor(false);
  };
  const hideCustomColor = () => {
    setShowCustomColor(false);
    if (value !== '') {
      onHide();
    }
  };
  return (
    <>
      <div className="circle-picker-container w-80 p-2">
        <Typography className="mb-1 ml-2" variant="bodyMedium" color="mirage">
          Choose a color
        </Typography>
        <div className="flex flex-wrap mx-auto w-full">
          {presetColors.map((color, index) => {
            let event = { hex: color };
            return <ColorCircle key={index} color={color} selectedColor={value} onClick={() => onChangeColor(event)} />;
          })}
          <div className="w-7 h-7 m-1 flex justify-center items-center">
            <div
              style={{ border: '#94AAC1 solid 1px' }}
              className="w-5 h-5 rounded-full cursor-pointer flex justify-center items-center"
              onClick={() => setShowCustomColor(true)}
            >
              <Icon size="xs" icon="plus" color="gray" />
            </div>
          </div>
        </div>
      </div>
      {showCustomColor ? (
        <SketchColorPicker value={value} onChange={onChange} onHide={hideCustomColor} disabled={disabled} />
      ) : null}
    </>
  );
}

CircleColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  presetColors: PropTypes.array,
};

export default CircleColorPicker;
