import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ColorPickerInput, FormCardSpaced, Input, Typography, WinRedInput } from 'modules/common';
import { pageTagColors } from '../fields';
import { noop } from 'lodash';

export const PageTagForm = ({ data, onChange, isSubTag = false }) => {
  const tagType = useMemo(() => {
    return isSubTag ? 'Sub-tag' : 'Tag';
  }, [isSubTag]);
  const onColorChange = ({ target: { value } }) => {
    onChange({ target: { name: 'color', value } });
  };
  return (
    <FormCardSpaced className="mb-4">
      <div className="color-name flex items-flex-start">
        <ColorPickerInput
          label="Tag Color"
          value={data.color}
          name="acceptButtonAnimationColor"
          setColorAction={onColorChange}
          disabled={false}
          presetColors={pageTagColors}
          hideTextInput
          isCirclePicker
        />
        <WinRedInput
          name="name"
          value={data?.name || ''}
          placeholder={`${tagType} Name`}
          onChange={onChange}
          maxLength={25}
          classNameContainer="flex-1"
          validators={[{ validator: 'required', message: 'Tag name is required.' }]}
        />
      </div>
      <Input
        hidden
        value={data.color}
        onChange={noop}
        classNameWrapper="mt-2"
        validators={['required']}
        errorMessages={['Tag color is required.']}
      />
      <Typography className="mt-2 mb-4" italic>
        Keep your tag name short and memorable. 25 character limit.
      </Typography>
      <WinRedInput
        name="description"
        value={data?.description || ''}
        description="Give your tag a short description. 250 character limit."
        placeholder={`${tagType} description (Optional)`}
        onChange={onChange}
        maxLength={250}
      />
    </FormCardSpaced>
  );
};

PageTagForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubTag: PropTypes.bool,
};
