import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ConfirmModal,
  DrawerHeader,
  ExternalLink,
  NavTabs,
  NestedDrawer,
  SpinnerContainer,
  Typography,
  useToast,
} from 'modules/common';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ARCHIVE_CANDIDATE_PAGE_TAG,
  ARCHIVE_ORGANIZATION_PAGE_TAG,
  GET_TAG_HISTORY_CANDIDATE,
  GET_TAG_HISTORY_ORGANIZATION,
} from 'core/middleware/queries';
import { ActiveTagsListBody, HistoryTagsListBody } from './';
import { PageTagItem } from '../../page-tag.component';
import { toTitleCase } from 'core/utilities';
import { pageTagColors } from '../../fields';

export const ManageTagsDrawer = ({
  parentTags,
  drawer,
  toggleOpen,
  openMainDrawer,
  isOrganization,
  profileCandidate,
  onUpdateTagState,
  isDropdown,
  onPageRefetch,
  tagsUpdated,
  setTagsUpdated,
}) => {
  const [historyData, setHistoryData] = useState({});
  const [historyInitialized, setHistoryInitialized] = useState(false);
  const [filter, setFilter] = useState({
    activeTabKey: 'active',
    activeTabKeyName: 'Active',
  });
  const [archiveModal, setArchiveModal] = useState({
    display: false,
    selectedTag: null,
  });
  const onFilterChange = ({ target: { value } }) => {
    let newFilter = {
      activeTabKey: value,
      activeTabKeyName: toTitleCase(value),
    };
    setFilter(newFilter);
  };
  let backdropRef = useRef();
  const setbackdropRef = node => (backdropRef = node);
  const handleOnClose = () => {
    if (onPageRefetch && tagsUpdated) onPageRefetch();
    toggleOpen();
  };
  const setToast = useToast();

  const handleClickOutside = event => {
    const clickedBackdrop = drawer?.isOpen && backdropRef && backdropRef?.contains(event.target);
    if (clickedBackdrop) {
      handleOnClose();
    }
  };

  const [getTagHistory, { loading: getTagHistoryLoading, error: getTagHistoryError, refetch: getTagHistoryRefetch }] =
    useLazyQuery(isOrganization ? GET_TAG_HISTORY_ORGANIZATION : GET_TAG_HISTORY_CANDIDATE, {
      variables: {
        organizationRevvUid: profileCandidate.organization_revv_uid,
        page: 1,
        limit: 25,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const candidate =
          data?.viewer?.candidate?.tag_activities || data?.viewer?.state_level_organization?.tag_activities;
        setHistoryData(candidate);
      },
    });

  const [archiveTag, { loading: archiveTagLoading }] = useMutation(
    isOrganization ? ARCHIVE_ORGANIZATION_PAGE_TAG : ARCHIVE_CANDIDATE_PAGE_TAG
  );

  const updateTagState = tagToRemove => {
    let updateStateData = {
      actionTag: tagToRemove,
      action: 'ARCHIVED',
    };
    onUpdateTagState(updateStateData);
  };

  const handleArchiveTag = async tag => {
    let variables = {
      organizationRevvUid: profileCandidate.organization_revv_uid,
      tag: { revv_uid: tag.revv_uid },
    };
    try {
      const res = await archiveTag({ variables });
      const { errors } = res.data.candidateArchiveTag || res.data.organizationArchiveTag;
      if (errors) {
        setToast({ isError: true, message: errors });
        return;
      }
      setToast({ message: `${tag.name} tag has been archived.` });
      updateTagState(tag);
      setTagsUpdated(true);
      getTagHistoryRefetch();
    } catch (error) {
      console.error(error.message);
      setToast({ isError: true, message: error.message });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  useEffect(() => {
    if (filter?.activeTabKey === 'history') {
      if (!historyInitialized) {
        getTagHistory();
        setHistoryInitialized(true);
      }
    }
  }, [filter?.activeTabKey, historyInitialized, getTagHistory, profileCandidate.organization_revv_uid]);

  if (getTagHistoryError) {
    setToast({ isError: true, message: getTagHistoryError });
  }

  const handleCloseArchiveModal = () => {
    setArchiveModal({
      display: false,
      selectedTag: null,
    });
  };

  const handleOpenCreateTag = () => {
    openMainDrawer({ isEdit: false, data: { name: '', color: pageTagColors[0], description: '' } });
  };

  return (
    <>
      <NestedDrawer
        className="manage-tags-drawer overflow-x-hidden"
        setbackdropRef={setbackdropRef}
        open={drawer?.isOpen}
        toggleOpen={handleOnClose}
        icon="times"
        nestLevel={0}
        drawerPadding={false}
        footer={
          <div className="px-6 py-3">
            <Button variant="alt" onClick={handleOnClose} className="mr-3">
              Close
            </Button>
            <Button variant="primary" onClick={handleOpenCreateTag} className="mr-3">
              Create New Tag
            </Button>
          </div>
        }
      >
        <DrawerHeader
          toggleOpen={handleOnClose}
          classNameContainer="bg-white px-4 pt-4 pb-0 w-full"
          nextRow={
            <NavTabs
              activeTabKey={filter?.activeTabKey}
              activeTabKeyName={filter?.activeTabKeyName}
              onChangeFilter={onFilterChange}
              navTabs={[
                { value: 'active', label: 'Active' },
                { value: 'history', label: 'History' },
              ]}
              title="Manage Drawer"
              classNameContainer="mt-0 ml-1 justify-content-start"
            ></NavTabs>
          }
        >
          <div className="">
            <Typography variant="h2" className="mb-0">
              Manage Tag & Sub-Tags
            </Typography>
            <Typography className="mb-0">
              Use Tags or Sub-Tags to help categorize and organize content for easy navigation and search.&nbsp;
              <ExternalLink href="https://support.winred.com/en/articles/9740243-page-tags">Learn More</ExternalLink>
            </Typography>
          </div>
        </DrawerHeader>

        <div className="drawer-body py-6 px-4">
          {getTagHistoryLoading || archiveTagLoading ? (
            <SpinnerContainer />
          ) : filter?.activeTabKey === 'active' ? (
            <ActiveTagsListBody
              pageTags={parentTags}
              openMainDrawer={openMainDrawer}
              onArchive={handleArchiveTag}
              setArchiveModal={setArchiveModal}
            />
          ) : (
            <HistoryTagsListBody
              data={historyData}
              openMainDrawer={openMainDrawer}
              refetch={getTagHistoryRefetch}
              profileCandidate={profileCandidate}
            />
          )}
        </div>
      </NestedDrawer>
      <ConfirmModal
        show={archiveModal?.display}
        title="Archive Tag?"
        buttonText="Archive Tag"
        handleClose={handleCloseArchiveModal}
        onCancel={handleCloseArchiveModal}
        showCancelButton
        buttonColor="error"
        onSubmit={() => {
          handleArchiveTag(archiveModal?.selectedTag);
          handleCloseArchiveModal();
        }}
      >
        <div className="pt-4 mb-2 text-center">
          <div className="mb-0 font-normal text-2xl">
            Are you sure you want to archive the
            <span className="mx-1">
              <PageTagItem data={archiveModal?.selectedTag} />
            </span>
            Tag? It will be removed from all pages it is currently added to.
          </div>
        </div>
      </ConfirmModal>
    </>
  );
};

ManageTagsDrawer.propTypes = {
  parentTags: PropTypes.array.isRequired,
  drawer: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  openMainDrawer: PropTypes.func.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  onUpdateTagState: PropTypes.func.isRequired,
  isDropdown: PropTypes.bool,
  onPageRefetch: PropTypes.func,
  tagsUpdated: PropTypes.bool.isRequired,
  setTagsUpdated: PropTypes.func.isRequired,
};
