import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AddField,
  Button,
  ConfirmModal,
  DrawerHeader,
  ExternalLink,
  NestedDrawer,
  SpinnerContainer,
  Typography,
  useToast,
} from 'modules/common';
import { PageTagForm } from '../page-tag-form.component';
import { SubTagsListBody } from './sub-tags-list-body.component';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  addUpdateOrRemoveTagInList,
  checkIfRequiredDataFilled,
  checkIfTagDataChanged,
  formatTagDataForSever,
} from '../../tags.tools';
import {
  ARCHIVE_CANDIDATE_PAGE_TAG,
  ARCHIVE_ORGANIZATION_PAGE_TAG,
  GET_CANDIDATE_PAGE_TAGS,
  GET_ORGANIZATION_PAGE_TAGS,
  SAVE_CANDIDATE_PAGE_TAG,
  SAVE_ORGANIZATION_PAGE_TAG,
} from 'core/middleware/queries';
import { ValidationBlock } from 'core/validation';
import { PageTagItem } from '../../page-tag.component';
import { pageTagColors } from '../../fields';

export const MainTagDrawer = ({
  drawer,
  toggleOpen,
  onChange,
  openSubDrawer,
  isManageTagsDrawerOpen,
  profileCandidate,
  isOrganization,
  onUpdateTagState,
  setTagsUpdated,
}) => {
  const [initialTagData, setInitialTagData] = useState(drawer?.data);
  const [isInitialized, setIsInitialized] = useState(false);
  const setToast = useToast();
  const [subSearchText, setSubSearchText] = useState('');
  const [searchSubTags, setSearchSubTags] = useState([]);
  const [archiveModal, setArchiveModal] = useState({
    display: false,
    selectedTag: null,
  });
  let backdropRef = useRef();
  const setbackdropRef = node => (backdropRef = node);
  const handleOnClose = () => {
    setIsInitialized(false);
    toggleOpen();
  };

  const disableSaveButton = useMemo(() => {
    return checkIfTagDataChanged(drawer?.data, initialTagData) || !checkIfRequiredDataFilled(drawer?.data);
  }, [drawer?.data, initialTagData]);

  const validationBlock = createRef();

  const onChangeData = useCallback(
    ({ target: { name, value } }) => {
      let newValue = {
        ...drawer,
        data: {
          ...drawer?.data,
          [name]: value,
        },
      };
      onChange(newValue);
    },
    [drawer, onChange]
  );

  const [saveMainTag, { loading: saveMainTagLoading }] = useMutation(
    isOrganization ? SAVE_ORGANIZATION_PAGE_TAG : SAVE_CANDIDATE_PAGE_TAG
  );

  const [archiveTag, { loading: archiveTagLoading }] = useMutation(
    isOrganization ? ARCHIVE_ORGANIZATION_PAGE_TAG : ARCHIVE_CANDIDATE_PAGE_TAG
  );

  const initialVariables = useMemo(() => {
    return {
      organizationRevvUid: profileCandidate.organization_revv_uid,
      parentTagRevvUid: drawer?.data?.revv_uid,
      limit: 25,
    };
  }, [drawer?.data?.revv_uid, profileCandidate.organization_revv_uid]);

  const [getSubTags, { loading: getSubTagsLoading, error }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_PAGE_TAGS : GET_CANDIDATE_PAGE_TAGS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const candidate = data.viewer.candidate || data.viewer.state_level_organization;
        const tags = candidate?.tags;
        if (subSearchText === '') {
          onChangeData({ target: { name: 'subTags', value: { ...tags, results: tags?.results } } });
        } else {
          setSearchSubTags({ ...tags, results: tags?.results });
        }
      },
    }
  );

  const onGetSubTags = useCallback(
    async queryVariables => {
      let variables = {
        ...initialVariables,
      };
      if (queryVariables) {
        variables = {
          ...variables,
          ...queryVariables,
        };
      }
      await getSubTags({ variables });
    },
    [getSubTags, initialVariables]
  );

  const updateSubTags = (updatedTag, action) => {
    let isRemove = action === 'remove';
    let mainDataCount = drawer?.data?.subTags?.total_count || 0;
    let searchDataCount = searchSubTags?.total_count || 0;
    if (isRemove) {
      mainDataCount--;
      searchDataCount--;
    }

    onChangeData({
      target: {
        name: 'subTags',
        value: {
          ...drawer?.data?.subTags,
          total_count: mainDataCount,
          results: addUpdateOrRemoveTagInList(updatedTag, action, drawer?.data?.subTags?.results),
        },
      },
    });

    if (subSearchText !== '') {
      setSearchSubTags({
        ...searchSubTags,
        page_count: searchDataCount,
        results: addUpdateOrRemoveTagInList(updatedTag, action, searchSubTags?.results),
      });
    }
  };

  const onSaveTag = async () => {
    if (validationBlock.current.errors()) {
      setToast({
        message: 'Please correct the errors on this page before saving!',
        isError: true,
      });
      return;
    }
    const variables = {
      tag: formatTagDataForSever(drawer?.data, drawer?.isEdit),
      organizationRevvUid: profileCandidate.organization_revv_uid,
    };
    try {
      const res = await saveMainTag({ variables });
      const candidate = res.data.candidateCreateOrUpdateTag || res.data.organizationCreateOrUpdateTag;
      const { errors, tag } = candidate;
      if (errors) {
        setToast({ isError: true, message: errors });
        return;
      }
      let updateStateData = {
        actionTag: tag,
        action: drawer?.isEdit ? 'UPDATED' : 'CREATED',
      };
      if (drawer?.isEdit) {
        setTagsUpdated(true);
        updateStateData.oldTag = drawer?.data?.oldData;
      }
      setToast({ message: `${tag.name} tag has been ${drawer?.isEdit ? 'edited' : 'created'}.` });
      onUpdateTagState(updateStateData);
      handleOnClose();
    } catch (error) {
      console.error(error);
      setToast({ isError: true, error: error.message });
    }
  };

  const updateSubTagState = subTag => {
    let updateStateData = {
      actionTag: subTag,
      action: 'ARCHIVED',
      isSubTag: true,
    };
    onUpdateTagState(updateStateData);
    updateSubTags(subTag, 'remove');
  };

  const handleArchiveSubTag = async tag => {
    const variables = {
      organizationRevvUid: profileCandidate.organization_revv_uid,
      tag: { revv_uid: tag.revv_uid },
    };
    try {
      const res = await archiveTag({ variables });
      const { errors } = res.data.candidateArchiveTag || res.data.organizationArchiveTag;
      if (errors) {
        setToast({ isError: true, message: errors });
        return;
      }
      setToast({ message: `${tag.name} subtag has been archived.` });
      updateSubTagState(tag);
    } catch (error) {
      console.error({ isError: true, message: error });
    }
  };

  const handleClickOutside = event => {
    const clickedBackdrop = drawer?.isOpen && backdropRef && backdropRef?.contains(event.target);
    if (clickedBackdrop) {
      handleOnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  useEffect(() => {
    if (drawer?.isOpen) {
      if (!isInitialized) {
        if (drawer?.isEdit) {
          onGetSubTags();
        }
        setInitialTagData(drawer?.data);
        setIsInitialized(true);
      }
    }
    // eslint-disable-next-line
  }, [drawer?.isOpen, drawer?.isEdit, onGetSubTags]);

  if (error) {
    setToast({ isError: true, message: error });
  }

  const selectedSubTagsList = useMemo(() => {
    return subSearchText === '' ? drawer?.data?.subTags : searchSubTags;
  }, [drawer?.data?.subTags, subSearchText, searchSubTags]);

  const onAddNewSubTag = () => {
    setSearchSubTags({});
    setSubSearchText('');
    openSubDrawer({ isEdit: false, data: { color: pageTagColors[0], name: '', description: '' } });
  };

  const handleCloseArchiveModal = () => {
    setArchiveModal({ display: false, selectedTag: null });
  };

  return (
    <>
      <NestedDrawer
        className="main-tag-drawer"
        setbackdropRef={setbackdropRef}
        open={drawer?.isOpen}
        toggleOpen={handleOnClose}
        icon="times"
        nestLevel={isManageTagsDrawerOpen ? 1 : 0}
        footer={
          <div className="p-3">
            <Button variant="alt" onClick={handleOnClose} className="mr-3">
              Cancel
            </Button>
            <Button variant="success" disabled={disableSaveButton} onClick={onSaveTag}>
              {drawer?.isEdit ? 'Save' : 'Create'} Tag
            </Button>
          </div>
        }
      >
        <DrawerHeader toggleOpen={handleOnClose}>
          <Typography variant="h2" className="mb-0">
            {drawer?.isEdit ? 'Editing Tag' : 'Create New Tag'}
          </Typography>
          <Typography className="mb-0">
            Create Tags to help categorize and organize content for easy navigation and search.&nbsp;
            <ExternalLink href="https://support.winred.com/en/articles/9740243-page-tags">Learn More</ExternalLink>
          </Typography>
        </DrawerHeader>
        {drawer?.isOpen ? (
          <div className="drawer-body mb-5">
            <>
              {saveMainTagLoading ? (
                <SpinnerContainer />
              ) : (
                <ValidationBlock ref={validationBlock}>
                  <PageTagForm data={drawer?.data} onChange={onChangeData} />
                </ValidationBlock>
              )}
              {drawer?.isEdit ? (
                <>
                  <div className="sub-tag-header">
                    <Typography variant="h2" className="mb-0 mt-2">
                      Sub-tags
                    </Typography>
                    <Typography>
                      Sub-tags provide additional categorization within a main tag, allowing for more precise and
                      relevant grouping.&nbsp;
                      <ExternalLink href="https://support.winred.com/en/articles/9740243-page-tags">
                        Learn More
                      </ExternalLink>
                    </Typography>
                    <AddField className="mb-4" text="Create Sub-tag" onClick={onAddNewSubTag} />
                  </div>

                  {archiveTagLoading ? (
                    <SpinnerContainer />
                  ) : (
                    <div className="pb-10">
                      <SubTagsListBody
                        drawer={drawer}
                        data={selectedSubTagsList}
                        openSubDrawer={openSubDrawer}
                        getSubTags={onGetSubTags}
                        subSearchText={subSearchText}
                        setSubSearchText={setSubSearchText}
                        setSearchPool={setSearchSubTags}
                        loading={getSubTagsLoading}
                        setArchiveModal={setArchiveModal}
                      />
                    </div>
                  )}
                </>
              ) : null}
            </>
          </div>
        ) : null}
      </NestedDrawer>
      <ConfirmModal
        show={archiveModal?.display}
        title="Archive Tag?"
        buttonText="Archive Sub-Tag"
        handleClose={handleCloseArchiveModal}
        onCancel={handleCloseArchiveModal}
        showCancelButton
        buttonColor="error"
        onSubmit={() => {
          handleArchiveSubTag(archiveModal?.selectedTag);
          handleCloseArchiveModal();
        }}
      >
        <div className="pt-4 mb-2 text-center">
          <div className="mb-0 font-normal text-2xl">
            Are you sure you want to archive the
            <span className="mx-1">
              <PageTagItem data={archiveModal?.selectedTag} />
            </span>
            Sub-Tag? It will be removed from all pages it is currently added to.
          </div>
        </div>
      </ConfirmModal>
    </>
  );
};

MainTagDrawer.propTypes = {
  drawer: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  openSubDrawer: PropTypes.func.isRequired,
  isManageTagsDrawerOpen: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  onUpdateTagState: PropTypes.func.isRequired,
  setTagsUpdated: PropTypes.func.isRequired,
};
