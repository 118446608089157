import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TAG_DRAWER_TYPES } from '../fields';
import { MainTagDrawer, ManageTagsDrawer, SubTagDrawer } from './';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';

export const PageTagDrawerContainer = ({
  parentTags,
  updateParentTags,
  drawer,
  onChange,
  onUpdateTagState,
  existingTags,
  isDropdown,
  onChangeSelectFilter,
  onPageRefetch,
}) => {
  const [tagsUpdated, setTagsUpdated] = useState(false);
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const onCloseDrawer = name => {
    let newValue = {
      isOpen: false,
      nestLevel: 0,
    };
    if (name !== TAG_DRAWER_TYPES.MANAGE_TAGS) {
      newValue.isEdit = false;
      newValue.data = {};
    }
    onChange({ name, value: newValue });
  };

  const openDrawer = ({ name, isEdit, data }) => {
    let newValue = {
      isOpen: true,
      isEdit,
    };
    if (data) {
      newValue.data = data;
    }
    onChange({ name, value: { ...drawer[name], ...newValue } });
  };

  const getDrawerProps = name => {
    return {
      drawer: drawer[name],
      isManageTagsDrawerOpen: drawer[TAG_DRAWER_TYPES.MANAGE_TAGS]?.isOpen,
      toggleOpen: () => onCloseDrawer(name),
      onChange: value => onChange({ name, value }),
      onChangeMainDrawer: value => onChange({ name: TAG_DRAWER_TYPES.MAIN_TAG, value }, true, TAG_DRAWER_TYPES.SUB_TAG),
      openMainDrawer: ({ isEdit, data }) => openDrawer({ name: TAG_DRAWER_TYPES.MAIN_TAG, isEdit, data }),
      openSubDrawer: ({ isEdit, data }) => openDrawer({ name: TAG_DRAWER_TYPES.SUB_TAG, isEdit, data }),
      mainTagDrawer: drawer[TAG_DRAWER_TYPES.MAIN_TAG],
      subTagDrawer: drawer[TAG_DRAWER_TYPES.SUB_TAG],
      isOrganization,
      profileCandidate,
      parentTags,
      updateParentTags,
      onUpdateTagState,
      existingTags,
      isDropdown,
      onChangeSelectFilter,
      onPageRefetch,
      tagsUpdated,
      setTagsUpdated,
    };
  };

  return (
    <>
      <MainTagDrawer {...getDrawerProps(TAG_DRAWER_TYPES.MAIN_TAG)} />
      <SubTagDrawer {...getDrawerProps(TAG_DRAWER_TYPES.SUB_TAG)} />
      <ManageTagsDrawer {...getDrawerProps(TAG_DRAWER_TYPES.MANAGE_TAGS)} />
    </>
  );
};

PageTagDrawerContainer.propTypes = {
  parentTags: PropTypes.array.isRequired,
  updateParentTags: PropTypes.func,
  drawer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdateTagState: PropTypes.func.isRequired,
  existingTags: PropTypes.array.isRequired,
  isDropdown: PropTypes.bool.isRequired,
  onChangeSelectFilter: PropTypes.func,
  onPageRefetch: PropTypes.func,
};
