import gql from 'graphql-tag';
import { upsellGroupFragment, upsellFragment } from './upsells.queries';
import { widgetFragment } from '../widgets.queries';
import {
  campaignFragment,
  conduitPageCandidateFragment,
  conduitPageOrganizationFragment,
  conduitPagesCandidateFragment,
  conduitPagesOrganizationFragment,
  availablePacCandidateFragment,
  pageTagFragment,
} from './page-fragments';
import { partialUpsellPathwayFragment } from './pathways.queries';

export const fragments = {
  page: gql`
    fragment Page on Page {
      created_at
      updated_at
      authorEmail
      authorCallout
      revv_uid
      url
      internalName
      slug
      publicTitle
      type
      status
      password
      passwordExactMatch
      passwordCallout
      entryMode
      cssOverride
      callToAction
      submitButtonText
      steps
      qrCodeUrl

      backgroundColor
      fixedBackground
      backgroundAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }

      shippingAddressPrechecked
      recurringUpsell {
        revv_uid
        conversionRate
      }
      smsOptinUpsell {
        revv_uid
        conversionRate
      }
      doubleUpsell {
        revv_uid
        conversionRate
      }
      primaryColor
      secondaryColor
      backgroundColor
      layout
      position
      enableShippingDetails
      sourceCode

      enableMobileField
      requireMobileField
      emailFieldRequired
      enableSmsOptin
      enableShippingDetails
      sourceCode
      enableRecaptcha
      smsOptinCallout
      mobileDisclaimerText

      tags {
        ...PageTag
      }

      pageAmounts {
        id
        amount
        position
        description
        preselected
      }

      thermometerCampaign {
        initiative_type
        thermometer_fields {
          goals {
            id
            amount_cents
            position
          }
          page_count
          boost_goal
          query_date
          data_source
        }
        statistics {
          donor_count
          donation_count
          donation_amount
        }
      }
      thermometerWidget {
        revvUid
        label
        name
        type
      }

      enableBusinessDonations
      enablePacDonations
      enablePersonalDonations
      enableAchPayments
      defaultPaymentOption

      recurringAvailable
      recurringIntervalSelectable
      recurringInterval
      recurringEndAt
      recurringEnableRadioButtons
      recurringDefaultValue
      recurringConfirmRadioButtonCallout
      recurringDeclineRadioButtonCallout
      recurringIntervalSelectable
      recurringCallout
      redirectPageRevvUid

      highDollar
      videoPage
      billPayExists
      pageFees {
        revvFeePercentage
      }

      upsellAgencyFeesEnabled
      recurringAgencyFeesEnabled

      confirmationRedirectBoolean
      confirmationParagraph
      confirmationRedirectUrl
      confirmationTwitterHandle
      confirmationFacebookHandle
      confirmationCustomLink
      confirmationCustomLinkCopy
      confirmationVideoEmbed
      confirmationWithUtms
      confirmationWithLeadInfo
      replaceUtmWithWrUtm
      confirmationPreviewPageUrl

      bundlerId
      showBundlerId
      locale
      disclaimer
      longDisclaimer
      officialDisclaimer
      hasOtherField
      logoLocation
      enablePhoneField
      requirePhoneField
      videoEmbed
      mobileBannerAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
      logoAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
      backgroundAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }

      displayLogoOnMobile
      customFields {
        page_custom_field_id
        global_custom_field_id
        name
        input_type
        position
        position_index
        form_section
        label
        default_value
        placeholder
        required
        unique_parameter
        other_field_enabled
        has_poll_results_widgets
      }

      splitEvenly
      petitionPageFields {
        hasFirstNameField
        hasLastNameField
        hasEmailField
        hasAddressField
        hasCityField
        hasStateField
        hasPostalCodeField
        hasPhoneField
      }

      pathway {
        name
        id
        revv_uid
        pathway_type
      }
      upsellProcessType

      ogTitle
      ogDescription
      ogVideo
      ogAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }

      twitterTitle
      twitterDescription
      twitterAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }

      upsellPageGroup {
        ...UpsellGroup
      }
      campaign {
        ...PageCampaign
      }
      moneyPledgeCampaign {
        revv_uid
        name
      }

      additionalMoneyPledgeCampaigns {
        moneyPledgeCampaign {
          revv_uid
          name
        }
      }
      preCheckMoneyPledge

      widgets {
        ...Widget
      }

      donorCoverFeesCallout
      donorCoverFeesPreChecked
      donorCoverFees
      description
      minimumAmount
      maximumAmount
    }
    ${upsellGroupFragment}
    ${widgetFragment}
    ${campaignFragment}
    ${pageTagFragment}
  `,
};

export const GET_PAGES_QUERY = gql`
  query getPages(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $status: StatusEnum
    $pageType: [TypeEnum!]
    $organizationRevvUid: String
    $highDollar: Boolean
    $videoPage: Boolean
    $feeType: VendorFeeFilterEnum
    $feeValue: String
    $profileRevvUid: String
    $tags: [String!]
    $tagsPredicate: SimplePredicateEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        allow_donations_to_process
        pages(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
            status: $status
            pageType: $pageType
            highDollar: $highDollar
            videoPage: $videoPage
            feesType: $feeType
            feesValue: $feeValue
            profileRevvUid: $profileRevvUid
            tags: $tags
            tagsPredicate: $tagsPredicate
          }
        ) {
          current_page
          page_count
          total_count
          raised_amounts {
            revv_uid
            raised_amount
            leads
          }
          results {
            revv_uid
            created_at
            updated_at
            authorEmail
            authorCallout

            upsellAgencyFeesEnabled
            recurringAgencyFeesEnabled
            highDollar
            videoPage
            billPayExists
            pageFees {
              revvFeePercentage
            }

            tags {
              ...PageTag
            }

            backgroundColor
            backgroundAssignedImage {
              id
              image {
                id
                thumbnailUrl
              }
            }

            internalName
            slug
            status
            url
            type
            qrCodeUrl
            conduitPageCandidates {
              ...ConduitPagesCandidate
            }
            conduitPageOrganizations {
              ...ConduitPagesOrganization
            }
          }
        }
        upsell_groups {
          total_count
        }
        upsells {
          total_count
        }
      }
    }
  }
  ${conduitPagesCandidateFragment}
  ${conduitPagesOrganizationFragment}
  ${pageTagFragment}
`;

export const GET_PAGE_QUERY = gql`
  query getPage(
    $revv_uid: String!
    $organizationRevvUid: String
    $defaultMoneyPledgeId: String
    $profileRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        slug
        pages(filters: { revvUid: $revv_uid, profileRevvUid: $profileRevvUid }) {
          results {
            ...Page
            highDollar

            pageFees {
              revvFeePercentage
            }
          }
        }
        money_pledges: campaigns(filters: { initiativeType: [MONEYPLEDGE], limit: 1, revvUid: $defaultMoneyPledgeId }) {
          results {
            revv_uid
            money_pledge_date
          }
        }
        maximum_contribution_cents
        campaigns {
          total_count
        }

        upsell_groups {
          total_count
        }
        upsells {
          total_count
        }
      }
    }
  }
  ${fragments.page}
  ${upsellGroupFragment}
`;

export const GET_PAGE_COMMITTEES = gql`
  query getPageCommittees($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pages(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            conduitPageCandidates {
              ...ConduitPageCandidate
            }
            conduitPageOrganizations {
              ...ConduitPageOrganization
            }
          }
        }
      }
    }
  }
  ${conduitPageCandidateFragment}
  ${conduitPageOrganizationFragment}
`;

export const GET_PAGE_CAMPAIGNS = gql`
  query getPageCampaigns($organizationRevvUid: String, $campaignsLimit: Int) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD, MERCHANDISE], limit: $campaignsLimit }) {
          results {
            ...PageCampaign
          }
        }
      }
    }
  }
  ${campaignFragment}
`;

export const SEARCH_PAGE_CAMPAIGNS = gql`
  query searchPageCampaigns($organizationRevvUid: String, $limit: Int, $search: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD, MERCHANDISE], limit: $limit, search: $search }) {
          results {
            ...PageCampaign
          }
        }
      }
    }
  }
  ${campaignFragment}
`;

export const SEARCH_PAGE_NAMES = gql`
  query searchPageNames($organizationRevvUid: String, $limit: Int, $search: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pages(filters: { limit: $limit, search: $search }) {
          results {
            revv_uid
            internalName
          }
        }
      }
    }
  }
`;

export const GET_NEW_PAGE_QUERY = gql`
  query getNewPageQuery(
    $defaultUpsellGroupId: String
    $organizationRevvUid: String
    $defaultMoneyPledgeId: String
    $defaultUpsellPathwayId: String
    $revvUid: String
    $pathwayType: PathwayTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        available_pac_candidates(filters: { revvUid: $revvUid }) {
          results {
            ...AvailablePacCandidate
          }
        }
        default_upsell_process_type
        default_upsell_group_revv_uid
        default_upsell_pathway_revv_uid
        default_source_code
        upsell_groups(filters: { revvUid: $defaultUpsellGroupId, limit: 1 }) {
          results {
            ...UpsellGroup
          }
        }
        upsells(filters: { limit: 1 }) {
          results {
            ...Upsell
          }
        }
        pathways(filters: { revvUid: $defaultUpsellPathwayId, pathwayType: $pathwayType, limit: 1 }) {
          results {
            ...PartialUpsellPathway
          }
        }
        money_pledges: campaigns(filters: { initiativeType: [MONEYPLEDGE], limit: 1, revvUid: $defaultMoneyPledgeId }) {
          results {
            revv_uid
            money_pledge_date
          }
        }
        default_setting {
          enableSmsOptin
          mobileDisclaimerText
          smsOptinCallout
          background {
            id
            image {
              id
              thumbnailUrl
            }
          }
          mobileBackground {
            id
            image {
              id
              thumbnailUrl
            }
          }
          logo {
            id
            image {
              id
              thumbnailUrl
            }
          }
        }
        marketing_setting {
          ogTitle
          ogVideo
          ogDescription
          ogAssignedImage {
            id
            image {
              id
              thumbnailUrl
            }
          }
          twitterTitle
          twitterDescription
          twitterAssignedImage {
            id
            image {
              id
              thumbnailUrl
            }
          }
        }
        campaigns {
          total_count
        }
      }
    }
  }
  ${upsellGroupFragment}
  ${upsellFragment}
  ${availablePacCandidateFragment}
  ${partialUpsellPathwayFragment}
`;

export const GET_ALL_CANDIDATES_QUERY = gql`
  query getPacCandidates($search: String, $limit: Int, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        available_pac_candidates(filters: { limit: $limit, search: $search }) {
          results {
            name
            can_access_vendors
            first_name
            last_name
            revv_uid
            office
            state
            district
            avatar_image_url
            avatar {
              id
              image {
                id
                thumbnailUrl
              }
            }
            available_vendors {
              id
              name
              defaultFeePercentage
            }
          }
        }
      }
    }
  }
`;

export const EXPORT_PETITION_PAGE_LEADS = gql`
  mutation CandidateExportPetitionPageLeads($page: ExportPageInput!, $organizationRevvUid: String) {
    candidateExportPetitionPageLeads(page: $page, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      dammed
      petitionPage {
        revv_uid
        __typename
      }
      __typename
    }
  }
`;

export const CREATE_PAGE_MUTATION = gql`
  mutation createPageMutation($page: CreatePageInput!, $organizationRevvUid: String) {
    candidateCreatePage(page: $page, organizationRevvUid: $organizationRevvUid) {
      errors
      page {
        ...Page
        campaign {
          ...PageCampaign
        }
      }
    }
  }
  ${fragments.page}
  ${campaignFragment}
`;

export const UPDATE_PAGE_MUTATION = gql`
  mutation updatePageMutation($page: UpdatePageInput!, $organizationRevvUid: String) {
    candidateUpdatePage(page: $page, organizationRevvUid: $organizationRevvUid) {
      errors
      warning
      page {
        ...Page
        campaign {
          ...PageCampaign
        }
      }
    }
  }
  ${fragments.page}
  ${campaignFragment}
`;

export const ARCHIVE_PAGE_MUTATION = gql`
  mutation ArchivePageMutation($page: ArchivePageInput!, $organizationRevvUid: String) {
    candidateArchivePage(page: $page, organizationRevvUid: $organizationRevvUid) {
      errors
      page {
        ...Page
        campaign {
          ...PageCampaign
        }
      }
    }
  }
  ${fragments.page}
  ${campaignFragment}
`;

export const UNARCHIVE_PAGE_MUTATION = gql`
  mutation unarchivePageMutation($page: UnarchivePageInput!, $organizationRevvUid: String) {
    candidateUnarchivePage(page: $page, organizationRevvUid: $organizationRevvUid) {
      errors
      page {
        status
        slug
      }
    }
  }
`;

export const DUPLICATE_PAGE_MUTATION = gql`
  mutation CandidateDuplicatePage($page: DuplicatePageInput!, $organizationRevvUid: String) {
    candidateDuplicatePage(page: $page, organizationRevvUid: $organizationRevvUid) {
      errors
      duplicatedPage {
        revv_uid
      }
    }
  }
`;

export const CANDIDATE_BULK_DUPLICATE_PAGE_MUTATION = gql`
  mutation CandidateBulkDuplicatePage($pageDuplication: BulkDuplicatePageInput!, $organizationRevvUid: String) {
    candidateBulkDuplicatePage(bulkPageDuplication: $pageDuplication, organizationRevvUid: $organizationRevvUid) {
      duplicated_pages_result {
        duplicated_page {
          internalName
          revv_uid
          slug
        }
        errors
        page_duplication_parameters {
          templateRevvUid
          customName
          customSlug
        }
      }
    }
  }
`;

export const GET_CANDIDATE_REDIRECT_PAGES_QUERY = gql`
  query getRedirectPages($revv_uid: String!, $page: Int, $limit: Int, $organizationRevvUid: String!, $search: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        redirect_pages(filters: { revvUid: $revv_uid, page: $page, limit: $limit, search: $search }) {
          results {
            revv_uid
            internalName
            slug
          }
        }
      }
    }
  }
`;

export const GET_ALL_PAGES = gql`
  query getAllPages($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pages(filters: { limit: 9999 }) {
          results {
            revv_uid
            internalName
          }
        }
      }
    }
  }
`;

export const EXPORT_PAGE_DONATIONS = gql`
  mutation CandidateExportPageDonations($page: ExportPageInput!, $organizationRevvUid: String) {
    candidateExportPageDonations(page: $page, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      dammed
      donationPage {
        revv_uid
        __typename
      }
      __typename
    }
  }
`;

export const GET_PAGES_ID_NAME_QUERY = gql`
  query getPages($page: Int, $limit: Int, $organizationRevvUid: String, $search: String, $pageType: [TypeEnum!]) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        custom_field_pages(filters: { page: $page, limit: $limit, search: $search, pageType: $pageType }) {
          results {
            id
            revv_uid
            created_at
            authorEmail
            publicTitle
            internalName
            slug
            type
            status
            videoPage
            highDollar
            backgroundColor
            backgroundAssignedImage {
              id
              image {
                id
                thumbnailUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const CANDIDATE_BULK_PAGE_DUPLICATION = gql`
  mutation CandidateBulkDuplicatePage($pageDuplication: BulkDuplicatePageInput!, $organizationRevvUid: String) {
    candidateBulkDuplicatePage(bulkPageDuplication: $pageDuplication, organizationRevvUid: $organizationRevvUid) {
      process_id
    }
  }
`;
export const CANDIDATE_BULK_PAGE_RETRY_DUPLICATION = gql`
  mutation CandidateBulkDuplicatePageRetry(
    $bulkPageDuplication: BulkDuplicatePageInput!
    $organizationRevvUid: String
  ) {
    candidateBulkDuplicatePageRetry(
      bulkPageDuplication: $bulkPageDuplication
      organizationRevvUid: $organizationRevvUid
    ) {
      duplicated_pages_result {
        duplicated_page {
          revv_uid
          internal_name
          slug
          url
        }
        page_duplication_parameters {
          template_revv_uid
          custom_name
          custom_slug
        }
        template {
          partnership_templates {
            name
            feePercentage
            thumbnailUrl
          }
        }
        errors
        page_status
      }
    }
  }
`;

export const CANDIDATE_GET_BULK_DUPLICATE_PAGES_BACKGROUND_JOB = gql`
  query getBackgroundJob($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        background_job_result(revvUid: $revv_uid) {
          revv_uid
          status
          results {
            duplicated_page {
              revv_uid
              internal_name
              slug
              url
            }
            page_duplication_parameters {
              template_revv_uid
              custom_name
              custom_slug
              custom_source_code
            }
            template {
              partnership_templates {
                name
                feePercentage
                thumbnailUrl
              }
            }
            errors
            page_status
          }
        }
      }
    }
  }
`;
export const GET_CANDIDATE_SUBDOMAINS = gql`
  query getCandidateSubdomains($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        custom_subdomains_allowed
        organization_subdomains {
          id
          subdomain
          status
        }
        domain
      }
    }
  }
`;
export const GET_CANDIDATE_PAGE_TAGS = gql`
  query getCandidatePageTags(
    $organizationRevvUid: String!
    $search: String
    $parentTagRevvUid: String
    $parentTag: Boolean
    $page: Int
    $limit: Int
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        tags(
          filters: {
            search: $search
            parentTagRevvUid: $parentTagRevvUid
            parentTag: $parentTag
            limit: $limit
            page: $page
          }
        ) {
          current_page
          page_count
          total_count
          results {
            ...PageTag
          }
        }
      }
    }
  }
  ${pageTagFragment}
`;

export const GET_PAGE_TAGS_CANDIDATE = gql`
  query getPageTagsCandidate($revv_uid: String!, $organizationRevvUid: String, $profileRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pages(filters: { revvUid: $revv_uid, profileRevvUid: $profileRevvUid }) {
          results {
            tags {
              ...PageTag
            }
          }
        }
      }
    }
  }
  ${pageTagFragment}
`;

export const SAVE_CANDIDATE_PAGE_TAG = gql`
  mutation createCandidateTagMutation($tag: TagInput!, $organizationRevvUid: String) {
    candidateCreateOrUpdateTag(tag: $tag, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      tag {
        ...PageTag
      }
    }
  }
  ${pageTagFragment}
`;

export const CANDIDATE_BULK_ADD_PAGE_TAG = gql`
  mutation candidateTagPagesMutation(
    $filters: PageFilter
    $pageRevvUid: String
    $tags: [TagInput!]!
    $organizationRevvUid: String!
  ) {
    candidateTagPages(
      filters: $filters
      pageRevvUid: $pageRevvUid
      tags: $tags
      organizationRevvUid: $organizationRevvUid
    ) {
      dammed
      errors
      message
    }
  }
`;

export const ARCHIVE_CANDIDATE_PAGE_TAG = gql`
  mutation ArchiveTagMutation($tag: TagInput!, $organizationRevvUid: String) {
    candidateArchiveTag(tag: $tag, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
    }
  }
`;

export const GET_TAG_HISTORY_CANDIDATE = gql`
  query getTagActivities($organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        tag_activities(filters: { page: $page, limit: $limit }) {
          current_page
          page_count
          total_count
          results {
            activity_type
            created_at
            pages_count
            revv_uid
            tag_names
            creator_email
          }
        }
      }
    }
  }
`;

export const CREATE_FAVORITE_CANDIDATE_PAGE_TAG = gql`
  mutation candidateCreateFavoriteMutation($favorite: FavoriteInput!, $organizationRevvUid: String) {
    candidateCreateFavorite(favorite: $favorite, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
    }
  }
`;
export const DELETE_FAVORITE_CANDIDATE_PAGE_TAG = gql`
  mutation candidateDeleteFavorite($favorite: FavoriteInput!, $organizationRevvUid: String) {
    candidateDeleteFavorite(favorite: $favorite, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
    }
  }
`;
