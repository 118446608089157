import { LIST_PAGE_SIZE, amountBuilder, dateAndSearchBuilder } from 'modules/common';

export default function queryBuilder(filter, isOrganization, feesOptions, isMyPagesOnly, profile) {
  let variables = {
    page: filter.page || 1,
    limit: filter.limit || LIST_PAGE_SIZE,
  };

  // if page type is high dollar then we actually want DONATION page type AND set high dollar boolean - but to the ui looks like active tab
  if (filter.activeTabKey === 'high_dollar') {
    variables.pageType = isOrganization ? ['DONATION'] : ['CONDUIT'];
    variables.highDollar = true;
    variables.videoPage = false;
  } else if (filter.activeTabKey === 'video') {
    variables.pageType = isOrganization ? ['DONATION'] : ['CONDUIT'];
    variables.videoPage = true;
  } else if (['conduit', 'donation'].includes(filter.activeTabKey)) {
    variables.pageType = isOrganization ? ['DONATION'] : ['CONDUIT'];
    variables.videoPage = false;
    variables.highDollar = false;
  } else if (filter.activeTabKey && filter.activeTabKey !== 'all') {
    variables.pageType = [filter.activeTabKey.toUpperCase()];
  } else {
    variables.pageType = isOrganization ? ['PETITION', 'DONATION'] : ['PETITION', 'CONDUIT'];
  }

  if (isMyPagesOnly) {
    variables.profileRevvUid = profile.revv_uid;
  }

  if (filter?.tags && filter?.tags?.length > 0) {
    variables.tags = filter.tags.map(tag => {
      return tag.value.revv_uid;
    });
  }
  variables.tagsPredicate = filter?.tagsPredicate;

  variables = amountBuilder(variables, filter);
  variables = dateAndSearchBuilder({
    variables,
    search: filter.search,
    dateRangeStart: filter.dateRangeStart,
    dateRangeEnd: filter.dateRangeEnd,
  });

  if (filter.status && filter.status !== 'all') {
    variables.status = filter.status.toUpperCase();
  }

  if (filter.fees && filter.fees !== 'all') {
    const fee = feesOptions.find(feeOption => feeOption.value === filter.fees);

    variables.feeType = fee.type;
    variables.feeValue = fee.value;
  }

  return variables;
}
