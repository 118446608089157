import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'modules/common/icon.component';
import WinRedInput from 'modules/common/form/inputs/winred-input.component';
import './filters.scss';

function SearchInput({ name, value, onChangeFilter, placeholder, disabled, inputClass }) {
  const [active, setActive] = useState(false);
  const searchPlaceholder = `Search ${placeholder}`;

  return (
    <div className={classNames('search-input-container', { expand: active || value })}>
      <Icon icon="search" size="lg" color="gray" />
      <WinRedInput
        className="rounded-pill recording-sensitive long-search border-none"
        name={name}
        placeholder={searchPlaceholder}
        value={value || ''}
        onChange={onChangeFilter}
        disabled={disabled}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      />
    </div>
  );
}

SearchInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
};

SearchInput.defaultProps = {
  name: 'search',
  disabled: false,
  value: '',
};

export default SearchInput;
