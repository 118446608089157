import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IndexTableContextMenu, Typography } from 'modules/common';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { PageTagItem } from '../../../page-tag.component';
import { convertEpochTime } from 'core/utilities';

export const SubTagsTableMobile = ({ items, dropdownAction }) => {
  if (!items) return null;

  return items.map(item => {
    const { revv_uid, name, color, description, created_at, creator } = item;

    return (
      <Fragment key={revv_uid}>
        <div className="list-item-card" tabIndex="0" role="link">
          <Row className="no-gutters">
            <Col xs="9">
              <div className="top-row flex justify-between items-start">
                <div className="flex justify-start items-center">
                  <PageTagItem data={{ name, color }} />
                </div>
              </div>
              <Typography>{description}</Typography>

              <Typography>
                {convertEpochTime(created_at)} <br />
                {creator && `by ${creator}`}
              </Typography>
            </Col>
            <Col xs="3" className="flex justify-end">
              <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
                <Dropdown.Item eventKey="edit">Edit</Dropdown.Item>
                <Dropdown.Item eventKey="archive">Archive</Dropdown.Item>
              </IndexTableContextMenu>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  });
};

SubTagsTableMobile.propTypes = {
  items: PropTypes.array.isRequired,
  dropdownAction: PropTypes.func.isRequired,
};
