import gql from 'graphql-tag';

export const availablePacCandidateFragment = gql`
  fragment AvailablePacCandidate on DirectoryCandidate {
    revv_uid
    name
    first_name
    last_name
    office
    state
    district
    can_access_vendors
    avatar_image_url
    avatar {
      id
      image {
        id
        thumbnailUrl
      }
    }
    available_vendors {
      id
      name
      defaultFeePercentage
    }
  }
`;

export const campaignFragment = gql`
  fragment PageCampaign on Campaign {
    revv_uid
    name
    initiative_type
    recurring_donation_required
    merch_products {
      merchandise_minimum_price_cents
      merch_product {
        variants_count
        type
        name
        fulfillment_house
        price_cents
        product_images {
          image_url
        }
      }
    }
  }
`;

export const pagesCampaignFragment = gql`
  fragment PagesCampaign on Campaign {
    name
    merch_products {
      merch_product {
        name
        type
        fulfillment_house
        price_cents
      }
    }
  }
`;

export const conduitPageCandidateFragment = gql`
  fragment ConduitPageCandidate on ConduitPageCandidate {
    revv_uid
    candidate {
      ...AvailablePacCandidate
    }
    position
    defaultPercentage
    allowDataSharing
    vendorFees {
      id
      partnershipId
      name
      feePercentage
      mostRecentActivity {
        objectName
        objectType
        objectRevvUid
        objectLink
        activityType
        vendorName
        organizationName
        hostOrganizationName
        changedFrom
        changedTo
        date
        email
      }
    }
    pageAmounts {
      id
      revv_uid
      amount
      position
      description
    }
  }
  ${availablePacCandidateFragment}
`;

export const conduitPageOrganizationFragment = gql`
  fragment ConduitPageOrganization on ConduitPageOrganization {
    revv_uid
    organization {
      ...AvailablePacCandidate
    }
    position
    defaultPercentage
    allowDataSharing
    vendorFees {
      id
      partnershipId
      name
      feePercentage
      mostRecentActivity {
        objectName
        objectType
        objectRevvUid
        objectLink
        activityType
        vendorName
        organizationName
        hostOrganizationName
        changedFrom
        changedTo
        date
        email
      }
    }
  }
  ${availablePacCandidateFragment}
`;

export const conduitPagesCandidateFragment = gql`
  fragment ConduitPagesCandidate on ConduitPageCandidate {
    candidate {
      name
    }
    vendorFees {
      id
      partnershipId
      name
      feePercentage
    }
  }
`;

export const conduitPagesOrganizationFragment = gql`
  fragment ConduitPagesOrganization on ConduitPageOrganization {
    organization {
      name
    }
    vendorFees {
      id
      partnershipId
      name
      feePercentage
    }
  }
`;

/** used for create/edit campaign */
export const fullCampaignFragment = gql`
  fragment FullCampaignFragment on Campaign {
    name
    revv_uid
    created_at
    initiative_type
    shipping_disclaimer
    email_body_text
    recurring_donation_required

    statistics {
      donor_count
      donation_count
      donation_amount
    }

    merch_products {
      id
      merchandise_minimum_price_cents
      merch_product {
        name
        revv_uid
        price_cents
        variants_count
        product_images {
          image_url
        }
      }
    }

    campaign_segments {
      id
      revv_uid
      days_since_last_donation
      donation_count
      donation_count_from
      donation_count_from_days
      show_other_field
      refresh_trigger_count
      refresh_trigger_interval_seconds
      amount_array_string
      amount_array_type
      total_volume
      other_amount
      default_amount_index
      position
      use_control_group
      control_group_percentage
      segment_control_stats: segment_conversion(control_group: true) {
        views
        donations
        conversion
        raised
        control_group
        __typename
      }
      segment_variant_stats: segment_conversion(control_group: false) {
        views
        donations
        conversion
        raised
        control_group
        __typename
      }
    }
  }
`;

export const pageTagFragment = gql`
  fragment PageTag on Tag {
    revv_uid
    updated_at
    created_at
    creator
    deleted_at
    name
    description
    color
    parent_tag
    parent_tag_name
    has_child_tags
  }
`;
