import React from 'react';
import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';
import { ActiveTabTableMobile, ActiveTabTableDesktop } from './list-body';
import { EmptyList } from 'modules/common';
import { pageTagColors } from '../../../fields';

export const ActiveTagsListBody = ({ openMainDrawer, pageTags, setArchiveModal }) => {
  const dropdownAction = (eventKey, tag) => {
    switch (eventKey) {
      case 'manage':
        openMainDrawer({ isEdit: true, data: { ...tag, oldData: { ...tag } } });
        break;
      case 'archive':
        setArchiveModal({ display: true, selectedTag: tag });
        break;
      default:
        break;
    }
  };

  if (pageTags?.length === 0) {
    return (
      <EmptyList
        text="No Page Tags"
        description="Create a page tag to organize your pages."
        buttonText="Create Page Tag"
        icon="tag"
        onClick={() => openMainDrawer({ isEdit: false, data: { name: '', description: '', color: pageTagColors[0] } })}
      />
    );
  }

  return (
    <div className="mt-3 mb-20">
      <Breakpoint small down>
        <ActiveTabTableMobile items={pageTags} dropdownAction={dropdownAction} openMainDrawer={openMainDrawer} />
      </Breakpoint>
      <Breakpoint medium up>
        <ActiveTabTableDesktop items={pageTags} dropdownAction={dropdownAction} />
      </Breakpoint>
    </div>
  );
};

ActiveTagsListBody.propTypes = {
  openMainDrawer: PropTypes.func.isRequired,
  pageTags: PropTypes.array.isRequired,
  setArchiveModal: PropTypes.func.isRequired,
};
