export const pageTagColors = [
  '#C2584B',
  '#D08A80',
  '#E18CA9',
  '#DA5D81',
  '#D08CEF',
  '#8A61AC',
  '#7C87C1',
  '#91B0F3',
  '#63A5E7',
  '#6095D2',
  '#2E67AD',
  '#397980',
  '#71B69E',
  '#60A159',
  '#79984B',
  '#8CAC3A',
  '#C99950',
  '#C6794A',
  '#B36133',
  '#A1A1A1',
  '#939395',
  '#778593',
  '#1E2B3A',
];

export const TAG_DRAWER_TYPES = {
  MAIN_TAG: 'MAIN_TAG',
  SUB_TAG: 'SUB_TAG',
  MANAGE_TAGS: 'MANAGE_TAGS',
  BULK_ADD: 'BULK_ADD',
};

export const defaultDrawerFields = {
  [TAG_DRAWER_TYPES.MAIN_TAG]: {
    isOpen: false,
    isEdit: false,
    data: {
      name: '',
      description: '',
      color: pageTagColors[0],
      subTags: [],
    },
  },
  [TAG_DRAWER_TYPES.SUB_TAG]: {
    isOpen: false,
    isEdit: false,
    data: {
      name: '',
      description: '',
      color: pageTagColors[0],
      parentTagId: '',
    },
  },
  [TAG_DRAWER_TYPES.MANAGE_TAGS]: {
    isOpen: false,
  },
};

export const TAG_ACTIVITY_TYPE_MAP = {
  BULK_ADDED: 'Bulk Added',
  ARCHIVED: 'Archived',
};
