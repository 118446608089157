import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { NavList } from 'modules/common';
import { noop } from 'core/utilities';
import '../list-header.scss';
import classnames from 'classnames';

function NavTabs({
  title,
  activeTabKey,
  activeTabKeyName = 'activeTabKey',
  onChangeFilter,
  navTabs = [],
  disabled = false,
  children = null,
  classNameContainer,
}) {
  return (
    <Row
      className={classnames({
        'justify-content-center mt-4': !classNameContainer,
        [classNameContainer]: classNameContainer,
      })}
    >
      <Col lg="11" className="header-subheader">
        <div className="nav-list">
          {!navTabs || navTabs.length > 0 ? (
            <NavList
              activeKey={activeTabKey}
              onSelect={disabled ? noop : value => onChangeFilter({ target: { name: activeTabKeyName, value } }, true)}
              ariaLabel={`preset filters for ${title}`}
            >
              {navTabs.map(nav => {
                if (nav.isHidden) return null;
                return (
                  <NavList.Item key={nav.value} eventKey={nav.value} disabled={disabled}>
                    {nav.label}
                  </NavList.Item>
                );
              })}
            </NavList>
          ) : null}
        </div>
        {children}
      </Col>
    </Row>
  );
}

NavTabs.propTypes = {
  title: PropTypes.any.isRequired,
  activeTabKey: PropTypes.string,
  onChangeFilter: PropTypes.func,
  navTabs: PropTypes.any,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  classNameContainer: PropTypes.string,
};

export default NavTabs;
