import React, { createRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import { useSelector } from 'react-redux';

import { QRCodeModal } from 'modules/common/qr-code';

import { profileCandidateSelector } from 'core/login';
import {
  Pagination,
  useClipBoard,
  useExpandedRows,
  ConfirmModal,
  Typography,
  FormCardSpaced,
  FormCard,
  Select,
  Icon,
  FlexBinaryChoiceRow,
  SpinnerContainer,
} from 'modules/common';

import PagesTableDesktop from './list-body/desktop.component';
import PagesTableMobile from './list-body/mobile.component';
import { EmptyPageList } from './empty-page-list.component';

import {
  EXPORT_PETITION_PAGE_LEADS,
  EXPORT_ORGANIZATION_PETITION_PAGE_LEADS,
  EXPORT_PAGE_DONATIONS,
  EXPORT_ORGANIZATION_PAGE_DONATIONS,
  ARCHIVE_PAGE_MUTATION,
  ARCHIVE_ORGANIZATION_PAGE_MUTATION,
  GET_ORGANIZATION_REDIRECT_PAGES_QUERY,
  GET_CANDIDATE_REDIRECT_PAGES_QUERY,
} from '../../../core/middleware/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from '../../common';
import { BulkPageDrawer } from './bulk-page-drawer/bulk-page-drawer.component';
import { isEmptyList } from 'modules/common/lists/pagination.tools';
import { ValidationBlock } from 'core/validation';

function PagesListBody({
  candidate,
  onChangeFilter,
  handleCreateNewPage,
  handleDuplicatePage,
  filter,
  clearFilter,
  refetch,
  uiFilter,
  toggleBulkAddTag,
}) {
  const history = useHistory();
  const match = useRouteMatch();

  const profileCandidate = useSelector(profileCandidateSelector);
  const copyToClipBoard = useClipBoard();
  const setToast = useToast();

  const { pages } = candidate || {};
  const { current_page, page_count, total_count, results } = pages || {};
  const [bulkPageDrawer, setBulkPageDrawer] = useState({
    selectedPage: null,
    display: false,
  });
  const [expandedRows, expandItem] = useExpandedRows();
  const [showDuplicateModal, setShowDuplicateModal] = useState({
    modal: false,
    page: null,
  });
  const [archiveModal, setArchiveModal] = useState({
    display: false,
    page: null,
  });
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const [redirectPage, setRedirectPage] = useState(null);
  const [redirectPageOptions, setRedirectPageOptions] = useState([]);
  const [dropdownSearchValue, setDropdownSearchValue] = useState('');
  const [timeoutId, setTimeoutId] = useState('');
  const validationBlock = createRef();

  const [exportLeads] = useMutation(
    profileCandidate.isOrganization ? EXPORT_ORGANIZATION_PETITION_PAGE_LEADS : EXPORT_PETITION_PAGE_LEADS
  );
  const [exportDonations] = useMutation(
    profileCandidate.isOrganization ? EXPORT_ORGANIZATION_PAGE_DONATIONS : EXPORT_PAGE_DONATIONS
  );
  const [archivePage, { loading: archivePageLoading }] = useMutation(
    profileCandidate.isOrganization ? ARCHIVE_ORGANIZATION_PAGE_MUTATION : ARCHIVE_PAGE_MUTATION
  );

  const variables = useMemo(
    () => ({
      revv_uid: archiveModal?.page?.revv_uid,
      organizationRevvUid: profileCandidate.organization_revv_uid,
      page: 1,
      limit: 20,
    }),
    [archiveModal?.page?.revv_uid, profileCandidate]
  );

  const navigateToItem = uuid => {
    history.push(`${match.url}/${uuid}`);
  };

  const [qrPage, setQrPage] = useState(null);

  const closeDuplicateModal = () => {
    setShowDuplicateModal({
      modal: false,
      page: null,
    });
  };

  const toggleBulkPageDrawer = (selectedPage = null) => {
    if (bulkPageDrawer.display) {
      refetch();
    }
    setBulkPageDrawer({ selectedPage, display: !bulkPageDrawer.display });
  };

  const onChangeRedirect = ({ target: { name, value } }) => {
    if (name === 'shouldRedirect') return setShouldRedirect(value);
    if (name === 'redirectPage') {
      return setRedirectPage(redirectPageOptions.find(o => o.value === value));
    }
  };

  const clearRedirectPage = () => {
    setRedirectPage(null);
    setDropdownSearchValue('');
    setRedirectPageOptions([]);
  };

  const [loadOptions, { loading: loadingOptions }] = useLazyQuery(
    profileCandidate.isOrganization ? GET_ORGANIZATION_REDIRECT_PAGES_QUERY : GET_CANDIDATE_REDIRECT_PAGES_QUERY,
    {
      onCompleted: data => {
        const allResults = (data.viewer?.candidate || data.viewer?.state_level_organization).redirect_pages.results.map(
          result => ({
            label: result.internalName,
            value: result.revv_uid,
          })
        );
        setRedirectPageOptions(allResults);
      },
    }
  );

  const _setDropdownSearchValue = useCallback(
    value => {
      setDropdownSearchValue(value);

      // reset dropdown values so we don't show previous results before searching
      setRedirectPageOptions([]);

      if (timeoutId) clearTimeout(timeoutId);
      const _id = setTimeout(() => {
        if (value && value.length > 2) loadOptions({ variables: { ...variables, search: value } });
      }, 400);

      setTimeoutId(_id);
    },
    [setRedirectPageOptions, timeoutId, loadOptions, variables]
  );

  const handleArchivePage = async () => {
    if (validationBlock?.current?.errors()) return;
    try {
      const variables = {
        page: {
          revvUid: archiveModal?.page?.revv_uid,
          redirectPageRevvUid: redirectPage?.value,
          organizationRevvUid: profileCandidate.organization_revv_uid,
        },
        organizationRevvUid: profileCandidate.organization_revv_uid,
      };
      const res = await archivePage({ variables });
      const { errors } = res.data.candidateArchivePage || res.data.organizationArchivePage;
      if (errors) {
        setToast({ isError: true, message: errors });
        return;
      }
      refetch();
      setToast({ message: 'Your page has been archived.' });
    } catch (error) {
      console.error(error.message);
      setToast({ message: error, isError: true });
    }
  };

  const handleExportLeads = async (organizationRevvUid, pageUid) => {
    const variables = {
      organizationRevvUid: organizationRevvUid,
      page: {
        revvUid: pageUid,
        organizationRevvUid: organizationRevvUid,
      },
    };
    try {
      const { data } = await exportLeads({ variables });
      let { errors: error } = data.candidateExportPetitionPageLeads || data.organizationExportPetitionPageLeads;
      if (error && error.length > 0) {
        setToast({ message: error, isError: true });
        return;
      }
      setToast({ message: 'Your report is being generated. We will send you an email when it is ready.' });
    } catch (error) {
      setToast({ message: error, isError: true });
    }
  };
  const handleExportDonations = async (organizationRevvUid, pageUid) => {
    const variables = {
      organizationRevvUid: organizationRevvUid,
      page: {
        revvUid: pageUid,
        organizationRevvUid: organizationRevvUid,
      },
    };
    try {
      const { data } = await exportDonations({ variables });
      let { errors: error, message } = data.candidateExportPageDonations || data.organizationExportPageDonations;
      if (error && error.length > 0) {
        setToast({ message: error, isError: true });
        return;
      }
      setToast({ message });
    } catch (error) {
      setToast({ message: error, isError: true });
    }
  };

  const dropdownAction = (eventKey, page) => {
    switch (eventKey) {
      case 'copy':
        copyToClipBoard({
          text: page.url,
          toast: 'Page URL copied to clipboard.',
          logEventParams: {
            name: 'copy url',
            parameters: {
              'page id': page.revv_uid,
              status: page.status,
            },
          },
        });
        break;

      case 'duplicate-bill-pay':
        setShowDuplicateModal({
          modal: true,
          page,
        });
        break;

      case 'duplicate':
        handleDuplicatePage(page.revv_uid);
        break;
      case 'bulk-page':
        toggleBulkPageDrawer(page);
        break;

      case 'qrCode':
        setQrPage(page);
        break;

      case 'exportLeads':
        handleExportLeads(profileCandidate.organization_revv_uid, page.revv_uid);
        break;
      case 'exportDonations':
        handleExportDonations(profileCandidate.organization_revv_uid, page.revv_uid);
        break;
      case 'archive':
        setArchiveModal({ display: true, page });
        break;
      default:
        break;
    }
  };

  if (isEmptyList(results, filter)) {
    return (
      <>
        <EmptyPageList activeTabKey={filter.activeTabKey} handleCreateNewPage={handleCreateNewPage} />;
      </>
    );
  }

  if (archivePageLoading) {
    return <SpinnerContainer />;
  }

  return (
    <div className="mt-3">
      <Breakpoint small down>
        <PagesTableMobile
          items={results}
          navigateToItem={navigateToItem}
          dropdownAction={dropdownAction}
          profileCandidate={profileCandidate}
          expandItem={expandItem}
          expandedRows={expandedRows}
          refetch={refetch}
          uiFilter={uiFilter}
          toggleBulkAddTag={toggleBulkAddTag}
        />
      </Breakpoint>
      <Breakpoint medium up>
        <PagesTableDesktop
          items={results}
          navigateToItem={navigateToItem}
          dropdownAction={dropdownAction}
          profileCandidate={profileCandidate}
          expandItem={expandItem}
          expandedRows={expandedRows}
          refetch={refetch}
          uiFilter={uiFilter}
          toggleBulkAddTag={toggleBulkAddTag}
        />
      </Breakpoint>

      <QRCodeModal show={!!qrPage} handleClose={() => setQrPage(null)} url={qrPage?.qrCodeUrl} slug={qrPage?.slug} />
      <BulkPageDrawer
        open={bulkPageDrawer?.display}
        onClose={toggleBulkPageDrawer}
        page={bulkPageDrawer?.selectedPage}
        profileCandidate={profileCandidate}
        isOrganization={profileCandidate.isOrganization}
      />
      <Pagination
        currentPage={current_page}
        pageCount={page_count}
        totalCount={total_count}
        setPage={onChangeFilter}
        filter={filter}
        results={results}
        clearFilter={clearFilter}
      />

      <ConfirmModal
        show={showDuplicateModal.modal}
        buttonText="Duplicate Page"
        onSubmit={() => {
          handleDuplicatePage(showDuplicateModal.page.revv_uid);
          closeDuplicateModal();
        }}
        showCancelButton={true}
        cancelButtonText="Cancel"
        onCancel={closeDuplicateModal}
        handleClose={closeDuplicateModal}
      >
        <Typography variant="h4" className="mt-5 mb-3 mb-1 text-center">
          Are you sure you want to duplicate this page?
        </Typography>
        <p className="text-center mb-4">
          The page you are trying to duplicate has <span className="c--winred-red">Bill-Pay fees attached</span> to one
          or more candidates.
        </p>
      </ConfirmModal>
      <ConfirmModal
        title="Archive Page"
        classNameBody="pt-2"
        buttonText="Archive Page"
        buttonColor="cancel"
        handleClose={() => setArchiveModal({ display: false, page: null })}
        onSubmit={handleArchivePage}
        show={archiveModal.display}
      >
        <Typography className="mb-3">Are you sure you want to archive this page? This cannot be undone.</Typography>
        <FormCardSpaced
          classNameFooter="sub-background"
          footer={
            shouldRedirect && (
              <ValidationBlock ref={validationBlock}>
                <FormCard className="bg--catskill-white mt-0 border-0" classNameBody="p-0">
                  <Select
                    wrapperClassName="mb-0"
                    options={redirectPageOptions}
                    placeholder="Name of the page to redirect to"
                    value={redirectPage}
                    name="redirectPage"
                    onChange={onChangeRedirect}
                    onInputChange={_setDropdownSearchValue}
                    hideMenu={!dropdownSearchValue} // hide dropdown menu if no search has started
                    dropdownSearchValue={dropdownSearchValue}
                    filterOption={false}
                    isLoading={loadingOptions}
                    noOptionsMessage={
                      dropdownSearchValue.length < 3
                        ? 'Please enter at least 3 characters to search.'
                        : 'No results found.'
                    }
                    label=" "
                    isSearchable
                    prependSearchIcon
                    hideDropdownIndicator={!!redirectPage}
                    validators={['required']}
                    errorMessages={['Please select a page to redirect to']}
                    errorMessageClasses="mt-2"
                  />
                  {redirectPage ? (
                    <Icon
                      icon="times"
                      size="1x"
                      className="pointer custom-close-btn mr-2 mt-1"
                      onClick={clearRedirectPage}
                    />
                  ) : null}
                </FormCard>
              </ValidationBlock>
            )
          }
        >
          <FlexBinaryChoiceRow
            title="Would you like to redirect this page?"
            subTitle="Select a page to redirect this to after this page has been archived."
            value={shouldRedirect}
            name="shouldRedirect"
            onChange={onChangeRedirect}
          />
        </FormCardSpaced>
      </ConfirmModal>
    </div>
  );
}

PagesListBody.propTypes = {
  candidate: PropTypes.object,
  onChangeFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  handleCreateNewPage: PropTypes.func.isRequired,
  handleDuplicatePage: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  uiFilter: PropTypes.array.isRequired,
  toggleBulkAddTag: PropTypes.func.isRequired,
};

export default PagesListBody;
