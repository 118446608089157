import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../typography.component';
import { PageTagItem } from 'modules/pages/pages/page-tags/page-tag.component';
import './filters.scss';

const TagFilter = ({ filter, uiFilter, onChangeFilter, removeClearButton = false }) => {
  const pageTags = uiFilter.find(item => item.name === 'tags');
  if (!pageTags || !pageTags?.value?.length > 0) return null;
  const onRemoveTags = () => {
    onChangeFilter({ target: { name: 'tags', value: pageTags.defaultValue } }, true);
  };
  return (
    <div className="flex items-center tag-filter-container">
      <Typography variant="bodyMedium" color="mirage" className="mr-1 mb-0">
        Page Tags:
      </Typography>
      <div className="tag-list flex flex-wrap items-center gap-y-1.5 gap-x-2">
        {pageTags?.value?.map(tag => {
          const onRemove = () => {
            onChangeFilter(
              {
                target: {
                  name: 'tags',
                  value: filter.tags.filter(filter => filter.value.revv_uid !== tag.value.revv_uid),
                },
              },
              true
            );
          };
          return (
            <PageTagItem
              key={tag.value.revv_uid}
              data={tag.value}
              isFilterTag={!removeClearButton}
              onRemove={onRemove}
            />
          );
        })}
      </div>
      {removeClearButton ? null : (
        <Typography className="ml-2 cursor-pointer mb-0" onClick={onRemoveTags}>
          Remove Tags
        </Typography>
      )}
    </div>
  );
};

TagFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  uiFilter: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  removeClearButton: PropTypes.bool,
};

export default TagFilter;
