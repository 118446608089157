import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/common';
import { PageTagItem } from './page-tag.component';
import { TagMenuSelector } from './tag-menu-selector.component';
import { noop } from 'core/utilities';
import { sortTagsByLevel } from './tags.tools';

export const TagIndexList = ({ pageRevvUid, data, isMobile = false, refetch, isPageBuilder = false }) => {
  const [tagList, setTagList] = useState(data ? sortTagsByLevel(data) : []);
  const [showSelectMenu, setShowSelectMenu] = useState(false);
  const toggleSelectMenu = () => {
    setShowSelectMenu(!showSelectMenu);
  };

  const closeMenu = () => {
    setShowSelectMenu(false);
  };

  return (
    <>
      <div className="tag-container flex">
        <div
          onClick={toggleSelectMenu}
          className="border border-mystic w-7 h-7 rounded flex justify-center items-center mr-1 cursor-pointer"
        >
          <Icon icon="tag" className="w-3.5 h-3.5 m-0" />
        </div>
        <div className="tag-list flex flex-wrap gap-y-1.5 gap-x-2">
          {tagList?.map((item, index) => {
            return <PageTagItem key={index} data={item} />;
          })}
        </div>
      </div>
      <div className="relative">
        <TagMenuSelector
          pageRevvUid={pageRevvUid}
          isOpen={showSelectMenu}
          toggleMenu={closeMenu}
          existingTags={tagList}
          updatePageTags={setTagList}
          onPageRefetch={isPageBuilder ? noop : refetch}
          isMobile={isMobile}
          isPageBuilder={isPageBuilder}
        />
      </div>
    </>
  );
};

TagIndexList.propTypes = {
  pageRevvUid: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  refetch: PropTypes.func,
  isPageBuilder: PropTypes.bool,
};
