import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import { Button } from 'modules/common';

function SketchColorPicker({ value, onChange, onHide, disabled, presetColors }) {
  return (
    <div className="picker-container">
      <SketchPicker
        color={value}
        onChangeComplete={onChange}
        presetColors={presetColors}
        className="winred-sketch-picker"
        disableAlpha
        disabled={disabled}
      />
      <Button variant="primary" onClick={onHide}>
        Done
      </Button>
    </div>
  );
}

SketchColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  presetColors: PropTypes.array.isRequired,
};

export default SketchColorPicker;
