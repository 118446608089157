import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import { CircleColorPicker, FloatGroup, Input, SketchColorPicker } from 'modules/common';
import classnames from 'classnames';

export const ColorPickerInput = ({
  name,
  value,
  label,
  description,
  disabled,
  className,
  setColorAction,
  isCirclePicker = false,
  hideTextInput = false,
  presetColors = [],
}) => {
  const [isShowPicker, setIsShowPicker] = useState(false);

  let colorPickerRef = useRef();
  const setColorPickerRef = node => (colorPickerRef = node);

  const PickerComponentType = useMemo(() => {
    return isCirclePicker ? CircleColorPicker : SketchColorPicker;
  }, [isCirclePicker]);

  const handleClickOutsideColorPicker = event => {
    if (colorPickerRef && !colorPickerRef.contains(event.target)) {
      setIsShowPicker(false);
    }
  };

  const onChange = color => {
    setColorAction({ target: { name: name, value: color.hex } });
  };

  const onHide = () => setIsShowPicker(false);

  const toggleColorPicker = () => setIsShowPicker(prevState => !prevState);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideColorPicker, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideColorPicker, { capture: true });
    };
  });
  return (
    <div className={classnames('color-picker-row', className)} ref={setColorPickerRef}>
      <div className="color-picker-circle-wrapper">
        <div
          className={classname('color-picker-circle no-outline', { pointer: !disabled })}
          style={{ backgroundColor: value }}
          role={disabled ? null : 'button'}
          tabIndex={disabled ? null : '0'}
          onClick={disabled ? null : toggleColorPicker}
          onKeyPress={disabled ? null : toggleColorPicker}
        />
      </div>

      <div className="d-flex w-100 relative">
        {hideTextInput ? null : (
          <FloatGroup onClick={toggleColorPicker} className="w-100">
            <Input
              type="text"
              name={name}
              label={label}
              placeholder={label}
              value={value}
              readOnly
              disabled={disabled}
              maxLength={7}
              description={description}
              className="winred-input-color"
            />
          </FloatGroup>
        )}
        {isShowPicker && !disabled ? (
          <PickerComponentType
            value={value}
            onChange={onChange}
            onHide={onHide}
            disabled={disabled}
            presetColors={presetColors}
          />
        ) : null}
      </div>
    </div>
  );
};

ColorPickerInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setColorAction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ColorPickerInput;
