import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/common';

export const ColorCircle = ({ color, onClick, selectedColor }) => {
  return (
    <div className="w-7 h-7 m-1 flex justify-center items-center">
      {color === selectedColor ? (
        <div style={{ border: '#94AAC1 solid 1px' }} className="w-7 h-7 rounded-full flex justify-center items-center">
          <div
            style={{ backgroundColor: color }}
            className="w-5 h-5 rounded-full cursor-pointer flex justify-center items-center"
            onClick={onClick}
          >
            <Icon size="xs" icon="check" color="white" />
          </div>
        </div>
      ) : (
        <div style={{ backgroundColor: color }} className="w-5 h-5 rounded-full cursor-pointer" onClick={onClick}></div>
      )}
    </div>
  );
};

ColorCircle.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedColor: PropTypes.string,
};
